<template>
  <div id="list">
    <div class="ListBoxs">
      <b-list-group>
        <b-list-group-item button v-for="(item,index) in list" :key="index" class="ListBox"
                           @click="goNewsInfo(item.id,item.create_time)">
          <span>{{ index + 1 }}</span>
          <h4>{{ item.title }}</h4>
          <p>{{ item.create_time }}</p>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="paginationBox" v-if="total !== 0">
      <!-- <b-pagination v-model="currentPage" :total-rows="rows" align="center"></b-pagination> -->
      <el-pagination class="fengye" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="currentPage" :page-size="12"
                     layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex'
import {
  getNewList
} from '../../api/news'

export default {
  props: ['index'],
  data: () => ({
    index2: 0,
    total: 0,
    currentPage: 1,
    page_size: 12,
    list: [],
  }),
  computed: {
    ...mapState([
      'TabIndex'
    ]),
  },
  mounted() {
    this.index2 = this.$route.query.index - 0
    this.changeList(this.index2)
  },
  watch: {
    TabIndex() {
      this.changeList(this.index)
      this.index2 = this.$route.query.index - 0
    }
  },
  methods: {
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.changeList(this.index2)
    },
    handleSizeChange(page_size) {
      this.page_size = page_size
      this.changeList(this.index2)
    },
    // 获取新闻列表
    async getNewsLists(id) {
      const res = await getNewList({
        current_page: this.currentPage,
        page_num: this.page_size,
        cate_id: id - 0,
      })
      this.list = res.data.data
      this.total = res.data.total
      this.list.forEach((item, index) => {
        this.list[index].create_time = item.create_time.slice(0, 10)
      })
    },
    // 跳转新闻详情
    goNewsInfo(id, create_time) {
      this.$router.push(`/content/news/info?id=${id}&index=${this.index2}&routerindex=0`)
      sessionStorage.setItem('create_time', create_time)
    },
    // 更新新闻列表
    changeList(index) {
      if (index == 0) {
        this.getNewsLists(3)
        this.newlist = this.list
      } else if (index == 1) {
        this.getNewsLists(2)
        this.newlist = this.list2
      } else if (index == 2) {
        this.getNewsLists(1)
        this.newlist = this.list3
      } else if (index == 3) {
        this.getNewsLists(4)
        this.newlist = this.list4
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#list {
  position: relative;
  width: 100%;
  height: 514px;
  border: 1px #2073dfb4 solid;
}

.ListBoxs {
  width: 819px;
  padding-right: 2px;
  // height: 450px;
}

.ListBox {
  display: flex;
  justify-content: space-between;
  height: 35px;

  h4 {
    margin-right: 90px;
    width: 550px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.paginationBox {
  position: absolute;
  bottom: 20px;
  margin-top: 10px;
  right: 20px;
}

</style>
