import http from '../utils/common'

// 获取列表
export async function getNewList(data) {
    // return data
    var url = '/api/news/list'
    var type = 'get'
    return http.api(url, data, type)
}

// 获取详情
export async function getNewInfo(data) {
    // return data
    var url = '/api/news/info'
    var type = 'get'
    return http.api(url, data, type)
}

//获取地区
export async function getArea(data) {
    // return data
    var url = '/api/area/list'
    var type = 'get'
    return http.api(url, data, type)
}

//资料下载
export async function aterial(data) {
    // return data
    var url = '/api/material/list'
    var type = 'post'
    return http.api(url, data, type)
}

//获取精品课程
export async function course(data) {
    // return data
    var url = '/api/course/list'
    var type = 'post'
    return http.api(url, data, type)
}

//课程详情
export async function courseInfo(data) {
    // return data
    var url = '/api/course/info'
    var type = 'get'
    return http.api(url, data, type)
}

//获取新闻轮播图
export async function get_banner_list(data) {
    var url = '/api/banner/list'
    var type = 'post'
    return http.api(url, data, type)
}


