<template>
  <div id="projectContent2">
    <div class="navtitle">
      <b-breadcrumb class="navBox">
        <b-breadcrumb-item @click="goNewsList">{{ navTitle }}</b-breadcrumb-item>
        <b-breadcrumb-item active>详情</b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <h3>{{ title }}</h3>
    <div class="content" v-html="newHtml">
      问题内容
    </div>

  </div>
</template>

<script>
import {getNewInfo} from '../../api/news.js'

export default {
  data: () => ({
    navTitle: '',
    id: '',
    index: 0,
    newHtml: '',
    title: '问题标题',
  }),
  computed: {},
  watch: {},
  created() {
    this.id = this.$route.query.id
    this.getInfo()
  },
  mounted() {
    this.navTitle = sessionStorage.getItem('navTitle')
  },
  methods: {
    goNewsList() {
      this.$router.push('/help/problem?index=0')
    },
    async getInfo() {
      const res = await getNewInfo({id: this.id})
      this.title = res.data.title
      this.newHtml = res.data.content
    }
  }
}
</script>

<style lang="scss" scoped>
#projectContent {
  min-height: 626px;
  width: 100%;

  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}

.content {
  padding: 20px;
  padding-top: 0;
}

.navtitle {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 17px;
  padding-left: 20px;
}

.navBox::v-deep .breadcrumb-item {
  color: #dddddd;
}

.navBox::v-deep a {
  color: #fff;
  text-decoration: none;
}
</style>
