import { render, staticRenderFns } from "./OperatPracticeList.vue?vue&type=template&id=a1a264ca&scoped=true&"
import script from "./OperatPracticeList.vue?vue&type=script&lang=js&"
export * from "./OperatPracticeList.vue?vue&type=script&lang=js&"
import style0 from "./OperatPracticeList.vue?vue&type=style&index=0&id=a1a264ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1a264ca",
  null
  
)

export default component.exports