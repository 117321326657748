<template>
  <div class="box">
    <qutab :titleName="title"/>
    <div class="training">
      <TrainTest :title="title2" :name="'pxks'"/>
    </div>
    <div class="query">
      <tab :titleName="listTitle" :list="Tablist" :id="'jpkc'"/>
    </div>
  </div>
</template>

<script>
import {
  getNewList
} from '../../api/news'
import TrainTest from '../home/train_test.vue'
import tab from '../tab/homeTab.vue'
import qutab from '../home/channel_dist.vue'

export default {
  components: {
    tab,
    TrainTest,
    qutab
  },
  data: () => ({
    title3: "常见问题",
    title: "渠道分布",
    title2: "培训考试",
    listTitle: '精品课程',
    problemList: [],
    Tablist: [],
  }),
  created() {
    this.getNewsLists()
    // this.getNewsLists3()
    this.getdataList()
  },
  computed: {},
  methods: {
    // 或者下载资料列表
    async getdataList() {
      const res = await getNewList({
        page_num: 3,
        cate_id: 10
      })
      this.Tablist = res.data.data
    },
    // 获取新闻列表
    async getNewsLists() {
      const res = await getNewList({
        page_num: 3,
        cate_id: 24,
      })
      this.problemList = res.data.data
    },
  }
}
</script>

<style lang="scss" scoped>
#carousel {
  width: 270px;
  height: 313px;
  overflow: hidden;
  margin-top: 10px;
  margin-left: 10px;
}

.training {
  height: 175px;
}

#carousel ::v-deep img {
  width: 100%;
  height: 100%;
}

.box {
  display: flex;
}

.industryNews {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  .industryNewsBox {
    width: 302px;
    height: 156.5px;

    .title {
      display: inline-block;
      border-bottom: 1px #003366 solid;
      margin: 0;
      padding: 5px 0;
      font-weight: 700;
      color: #003366;
    }

    .list {
      width: 100%;
      border-top: 1px #003366 solid;
    }
  }
}

.query {
  margin-left: 10px;
  height: 175px;
}
</style>
