<template>
  <div id="projectContent">
    <h3>{{ info.title }}</h3>
    <div class="content"
         v-html="info.content">
    </div>
  </div>
</template>

<script>
import {getNewInfo} from '../../api/news'
import {mapState} from 'vuex'

export default {
  // props: ['title'],
  data: () => ({
    index: 0,
    info: {
      content: '',
      title: ''
    }
  }),
  computed: {
    ...mapState([
      'TabIndex'
    ])
  },
  watch: {
    $route() {
      this.index = this.$route.query.index
      this.changeHtml()
    },
  },
  created() {
    console.log('this.title', this.title)
  },
  mounted() {
    this.index = this.$route.query.index
    this.changeHtml()
  },
  methods: {
    // 获取详情
    async getInfo(id) {
      const res = await getNewInfo({
        id
      })
      this.info = res.data
    },
    changeHtml() {
      if (this.index == 0) {
        this.getInfo(1)
        // this.newHtml = this.html1
      } else if (this.index == 1) {
        this.getInfo(2)
        // this.newHtml = this.html2
      } else if (this.index == 2) {
        this.getInfo(3)
        // this.newHtml = this.html2
      } else if (this.index == 3) {
        this.getInfo(4)
        // this.newHtml = this.html3
      } else if (this.index == 4) {
        this.getInfo(5)
        // this.newHtml = this.html4
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#projectContent {
  min-height: 584px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;

  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}

.content {
  padding: 20px;
}
</style>
