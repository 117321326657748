import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		TabIndex: 0,
		title: ''
	},
	mutations: {
		changeIndex(state, index) {
			this.state.TabIndex = index
		},
		changeTitle(state, title) {
			this.state.title = title
		}
	},
	getters: {

	},
	actions: {},
	modules: {}
})
