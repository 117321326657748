<template>
  <div id="userHelp">
    <b-row>
      <b-col cols="3">
        <Tab/>
        <Query/>
      </b-col>
      <b-col cols="9">
        <div v-if="!IsContent">
          <list/>
        </div>
        <div v-if="IsContent">
          <Content/>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Tab from '../../components/teacherHall/tab.vue'
import Query from '../../components/global/query.vue'
import list from '../../components/teacherHall/list.vue'
import Content from '../../components/teacherHall/content.vue'

export default {
  components: {
    Tab,
    Query,
    list,
    Content
  },
  data: () => ({
    path: '',
    IsContent: false,
  }),
  created() {
    this.path = this.$route.path
    if (this.$route.query.id) {
      this.IsContent = true
      console.log('this.$route.query.id', this.$route.query.id)
    } else {
      this.IsContent = false
    }
  },
  watch: {
    $route(newPath) {
      this.path = newPath.path
      if (this.$route.query.id) {
        this.IsContent = true
        console.log('this.$route.query.id', this.$route.query.id)
      } else {
        this.IsContent = false
      }
    },
  }
}
</script>
<style scoped lang="scss">
#userHelp {
  margin-bottom: 10px;
}
</style>
