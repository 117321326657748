<template>
  <div id="institutions">
    <div class="title" />
    <el-form
      :inline="true"
      :model="formInline"
      ref="formInlineRef"
      class="FormBox"
    >
      <div style="display: flex; justify-content: space-between">
        <div>
          <el-form-item label="地区" prop="province" style="margin-right: 40px">
            <el-select
              size="small"
              v-model="formInline.province"
              filterable
              placeholder="请选择地区"
            >
              <el-option
                v-for="item in areaList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="编号" prop="number" style="margin-left: 13px">
            <el-input
              clearable
              size="small"
              v-model="formInline.number"
              placeholder="请输入编号"
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item
            label="资质"
            prop="qualification"
            style="margin-right: 40px"
          >
            <el-input
              clearable
              size="small"
              v-model="formInline.qualification"
              placeholder="请输入资质"
            ></el-input>
          </el-form-item>
          <el-form-item label="关键字" prop="name">
            <el-input
              clearable
              size="small"
              v-model="formInline.name"
              placeholder="请输入关键字"
            ></el-input>
          </el-form-item>
        </div>
        <div style="margin-top: 60px">
          <el-form-item>
            <el-button
              style="margin-left: 10px"
              type="primary"
              size="small"
              @click="onSubmit"
              >查询</el-button
            >
            <el-button size="small" @click="resetForm('formInlineRef')"
              >重置</el-button
            >
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="tableBox">
      <el-table
        :data="items"
        style="width: 100%"
        size="mini"
        @sort-change="change_sort"
      >
        <el-table-column prop="index" label="序号" width="68">
        </el-table-column>
        <el-table-column prop="number" label="编号" width="90">
        </el-table-column>
        <el-table-column
          prop="province"
          label="地区"
          sortable="custom"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="merchant_quality"
          label="资质"
          width="190"
          sortable="custom"
        >
        </el-table-column>
        <el-table-column prop="name" label="单位名称"> </el-table-column>
      </el-table>
    </div>
    <div class="paginationBox">
      <el-pagination
        class="fengye"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getCoopList } from "../../api/coop.js";
import { getArea } from "../../api/news.js";

export default {
  data: () => ({
    province: "",
    areaList: [],
    total: 0,
    currentPage4: 1,
    formInline: {
      region: "",
      qualification: "",
      province: "",
      city: "",
      number: "",
      name: "",
    },
    rows: 100,
    currentPage: 1,
    items: [],
    sort: {
      merchant_quality: "asc",
    },
  }),
  created() {
    if (this.$route.query.code) {
      this.formInline.province = this.$route.query.code;
      this.getList();
    } else {
      this.getList();
    }
  },
  mounted() {
    this.getAreaList();
  },
  methods: {
    change_sort(column) {
      if (column.prop == "merchant_quality") {
        if (column.order == "ascending") {
          this.sort = {
            merchant_quality: "asc",
          };
          this.getList();
        } else {
          this.sort = {
            merchant_quality: "desc",
          };
          this.getList();
        }
      } else {
        if (column.order == "ascending") {
          this.sort = {
            province: "asc",
          };
          this.getList();
        } else if (!column.order) {
          this.sort = {
            merchant_quality: this.sort.merchant_quality,
          };
          this.getList();
        } else {
          this.sort = {
            province: "desc",
          };
          this.getList();
        }
      }
      console.log("column", column);
    },
    //获取地区
    async getAreaList() {
      const res = await getArea();
      this.areaList = res.data;
    },
    //重置按钮
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.push("/content/coop?index=3");
      this.formInline.province = "";
      this.getList();
      console.log("this.formInline", this.formInline);
    },
    //获取列表
    async getList() {
      const res = await getCoopList({
        current_page: this.currentPage,
        page_num: 15,
        qualification: this.formInline.qualification,
        number: this.formInline.number,
        name: this.formInline.name,
        province: this.formInline.province,
        order: this.sort,
      });
      if (res.code === 0) {
        this.total = res.data.total;
        this.items = res.data.data;
        this.items.forEach((item, index) => {
          this.items[index].index = index + 1;
        });
      }
    },
    // 提交表单查询按钮
    onSubmit() {
      this.getList();
      this.currentPage = 1;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
#institutions {
  position: relative;
  margin-top: 10px;
  height: 840px;
  border: 1px #2073dfb4 solid;
}

.title {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
}

.paginationBox {
  position: absolute;
  bottom: 20px;
  margin-top: 10px;
  right: 20px;
}

.tableBox {
  padding: 5px;
  font-size: 14px;
}

.FormBox {
  padding: 10px;
}

// 表格剧中
::v-deep.el-table th > .cell {
  text-align: center;
}

::v-deep.el-table .cell {
  text-align: center;
}
</style>
