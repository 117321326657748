<template>
  <div id="query">
    <div class="title">
      <p>证书查询</p>
      <!-- 	<el-input maxlength="15" size="mini" class="textInput" v-model="result" placeholder=" 测试使用,0失败,1成功">
      </el-input> -->
    </div>
    <div class="FormBox">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
        <el-form-item label="考生姓名" prop="name">
          <el-input clearable size="small" placeholder="请输入考生姓名" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="id_card">
          <el-input clearable size="small" placeholder="请输入身份证号" v-model="form.id_card"></el-input>
        </el-form-item>
        <el-form-item label="证书编号" prop="cert_no">
          <el-input clearable size="small" placeholder="请输入证书编号" v-model="form.cert_no"></el-input>
        </el-form-item>
        <el-form-item class="formItemStyle">
          <el-button size="small" type="primary" @click="submitForm('form')">查询</el-button>
          <el-button size="small" @click="resetForm('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 输入名字和身份证号成功查询结果 -->
    <el-dialog title="证书查询结果" center :visible.sync="dialogVisible" width="30%"
    >
      <div class="textBox" style="display:flex; justify-content: center">
        <div>
          <p>
            <span>姓名:</span><span>{{ resultForm.name }}</span>
          </p>
          <p>
            <span>性别:</span>
            <span>{{ resultForm.gender }}</span>
          </p>
          <p>
            <span>身份证:</span>
            <span>{{ resultForm.id_card }}</span>
          </p>
          <p>
            <span>学历:</span>
            <span>{{ resultForm.education }}</span>
          </p>
          <p>
            <span>获得证书:</span>
            <span>{{ resultForm.cert_name }}</span>
          </p>
          <p>
            <span>等级:</span>
            <span>{{ resultForm.grade }}</span>
          </p>
          <p>
            <span>证书编号:</span>
            <span>{{ resultForm.cert_no }}</span>
          </p>
          <p>
            <span>实践分数:</span>
            <span>{{ resultForm.application_score }}</span>
          </p>
          <p>
            <span>理论分数:</span>
            <span>{{ resultForm.written_score }}</span>
          </p>
          <p>
            <span>评定分数:</span>
            <span>{{ resultForm.result }}</span>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
    </el-dialog>
    <!-- 为查询到结果 -->
    <el-dialog title="证书查询结果" center :visible.sync="dialogVisible3" width="30%">
      <div class="textBox">
        <p>{{ message }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
			</span>
    </el-dialog>
  </div>
</template>

<script>
import {
  studentsInfo
} from '../../api/query.js';

export default {
  data() {
    return {
      message: '',
      resultForm: '',
      result: 1,
      form: {},
      rules: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'change'},
        ],
        id_card: [
          {required: true, message: '请输入身份证号', trigger: 'change'},
        ],
        cert_no: [
          {required: true, message: '请输入证书编号', trigger: 'change'},
        ]
      },
      dialogVisible: false,
      dialogVisible3: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await studentsInfo(this.form);
          if (res.code == 0) {
            this.resultForm = res.data;
            this.dialogVisible = true;
          } else {
            this.message = res.message
            this.dialogVisible3 = true;
          }
        } else {
          return false;
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style lang="scss" scoped>
#query {
  overflow: hidden;
  width: 249px;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  position: relative;
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
}

.sr-only {
  margin-top: 10px;
  font-size: 15px;
}

.FormBox {
  margin-top: 10px;
  padding: 10px;
  padding-bottom: 12px;
}

.btnBox {
  margin-left: 60px;
  margin-top: 15px;

  .Btn {
    margin: 0 5px;
  }
}

.textBox {
  font-size: 15px;

  p {
    margin: 5px 0;
  }
}

.textBox span:first-child {
  display: inline-block;
  width: 80px;
  text-align: right;
  margin-right: 20px;
}

.textBox span:last-child {
  font-size: 16px;
}

.textInput {
  position: absolute;
  top: 20px;
  left: 10px;
}

.formItemStyle ::v-deep .el-form-item__content {
  float: right;
  margin: 0 !important;
}
</style>
