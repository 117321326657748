<template>
  <div class="index">

    <!--    <div v-if="IsShowInstitutions">
          <Institutions />
        </div> -->
    <b-row>
      <b-col cols="3">
        <Tab :routerName="routerName"/>
        <Query/>
      </b-col>
      <b-col cols="9">
        <Content :routerName="routerName"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Tab from '../../components/tab/tab.vue'
import Query from '../../components/global/query.vue'
import Content from '../../components/content/Content.vue'
// import Institutions from '../../components/coopModel/institutions.vue'
export default {
  components: {
    Tab,
    Content,
    Query,
    // Institutions
  },
  data: () => ({
    pathStr: '',
    routerName: ''
  }),
  watch: {
    $route(to) {
      this.pathStr = to.path
      this.routerNameStr()
    }
  },
  computed: {
    ...mapState([
      'TabIndex'
    ]),
    IsShowInstitutions() {
      if (this.$route.path == '/content/coop' && this.TabIndex === 3) {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.pathStr = this.$route.path
    this.routerNameStr()
  },
  mounted() {
  },
  methods: {
    routerNameStr() {
      const str = '/content/'
      if (str + 'news' === this.pathStr) {
        this.routerName = '新闻中心'
      } else if (str + 'project' === this.pathStr) {
        this.routerName = '项目介绍'
      } else if (str + 'coop' === this.pathStr) {
        this.routerName = '合作模式'
      } else if (str + 'tech' === this.pathStr) {
        this.routerName = '技术体系'
      } else if (str + 'train' === this.pathStr) {
        this.routerName = '培训考试'
      } else if (str + 'operate' === this.pathStr) {
        this.routerName = '运营实训'
      } else if (str + 'online' === this.pathStr) {
        this.routerName = '在线教育'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.index {
  margin-bottom: 10px;
}
</style>
