<template>
  <div id="projectContent">
    <h3>{{ title }}</h3>
    <div class="content" v-html="newHtml">
    </div>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex'
import {
  getNewInfo
} from '../../api/news'

export default {
  data: () => ({
    index: 0,
    newHtml: '',
    title: '',
  }),
  computed: {
    ...mapState([
      'TabIndex',
    ])
  },
  watch: {
    $route() {
      this.index = this.$route.query.index
      console.log(this.index)
      this.changeHtml()
    },
    title(newTitle) {
      this.newTitle = newTitle
      this.changeHtml()
    }
  },
  created() {
  },
  mounted() {
    this.index = this.$route.query.index
    this.changeHtml()
  },
  methods: {
    // 获取详情
    async getInfo(id) {
      const res = await getNewInfo({
        id
      })
      this.title = res.data.title
      this.newHtml = res.data.content
    },
    changeHtml() {
      if (this.index == 0) {
        this.getInfo(60)
      } else if (this.index == 1) {
        this.getInfo(311)
      } else if (this.index == 2) {
        this.getInfo(48)
      } else if (this.index == 3) {
        this.getInfo(49)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#projectContent {
  min-height: 626px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;

  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}

.content {
  padding: 20px;
  padding-top: 0;
}
</style>
