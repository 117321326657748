<template>
  <div id="content">
    <div class="listBox">
      <div class="title">
        <p>{{title}}</p>
      </div>
      <div class="content">
        <div class="bookBox"
             v-for="(item,index) in bookList"
             :key="index"
             @click="goBookInfo(index,item.name)">
          <img :src="item.img"
               alt="">
          <p>{{item.name}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getNewList } from '../../../api/news'
export default {
  props: ['title'],
  data: () => ({
    bookList: [
      {
        img: './textBookImg/book1.png',
        name: '云计算'
      },
      {
        img: './textBookImg/book2.png',
        name: '物联网'
      },
      {
        img: './textBookImg/book3.jpg',
        name: '电子商务'
      },
      {
        img: './textBookImg/book4.jpg',
        name: '网络管理'
      }
    ]
  }),
  components: {
  },
  computed: {
    ...mapState([
      'TabIndex'
    ]),
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this.getBookList(9)
  },
  methods: {
    // 获取图书列表
    async getBookList (id) {
      const res = await getNewList({
        page_num: 10 - 0,
        cate_id: id - 0
      })
      console.log('res', res)
    },
    // 跳转图书详情
    goBookInfo (index, bookname) {
      console.log(bookname)
      this.$router.push(`/content/tech/info?id=${index}`)
      sessionStorage.setItem('bookname', bookname)
    }
  }

}
</script>
<style lang="scss" scoped>
// #content {
// }
.title {
  margin-top: 10px;
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 17px;
  padding-left: 30px;
}
.content {
  display: flex;
  .bookBox {
    margin: 20px;
    padding: 10px;
    border: 1px #000 solid;
    cursor: pointer;
  }
  img {
    width: 100px;
    height: 110px;
  }
  p {
    font-size: 15px;
    margin: 0;
    margin-top: 10px;
    text-align: center;
  }
}
.bookBox:hover {
  color: red;
}
</style>