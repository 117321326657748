<template>
  <div class="box">
    <div class="tabBox">
      <div class="titleBox">
        <h3>政策法规</h3>
      </div>
      <div class="list">
        <b-list-group>
          <b-list-group-item v-for="(item,index) in policies" :key="index" class="listBox"
                             @click="gonewsInfo(index,'zcfg',item.id)">
            <p class="textP2">
              <i class="iconfont icon-shijian" style="color: #ff9f5b;"></i>
              {{ item.create_time }}
            </p>
            <p class="textP1">
              {{ item.title }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="moreBox" @click="goMore('zcfg')" v-if="policies.length >=4"><span>更多
									<i class="iconfont icon-youjiantou"></i></span></p>
      </div>
    </div>

    <div class="longboBox">
      <el-carousel trigger="click" height="310px" id="carousel-fade" :interval="5000" arrow="never">
        <el-carousel-item class="imgBox" v-for="(item,index) in carouselList" :key="index">
          <a href="javascript:;" @click="go_news_info(item.link,item.link_type)">
            <img :src="item.picture_url">
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="queryBox">
      <Query/>
    </div>
  </div>
</template>

<script>
import {
  get_banner_list,
  getNewList
} from '../../api/news'
import Query from '../global/query.vue'

export default {
  components: {
    // tab,
    Query,
  },
  data: () => ({
    newslide: 0,
    Laws: "政策法规",
    LawsList: [],
    policies: [],
    dynamicList: [],
    newsList: [],
    carouselList: [],
    carouselValue: 0,
    slide: 0,
    sliding: null
  }),
  watch: {
    slide(newValue) {
      setTimeout(() => {
        this.newslide = newValue
      }, 500);
    }
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2
      }
    },
  },
  mounted() {
    this.getNewsLists()
    this.getNewsLists2()
    this.getNewsLists3()
    this.get_banner()
  },
  methods: {
    go_news_info(url, link_type) {
      if (link_type == 0) {
        window.open(url)
      } else {
        sessionStorage.setItem('navTitle', '媒体报道')
        this.$router.push(`/content/news/info?id=${url}&index=3&routerindex=0`)
      }
    },
    //获取轮播图列表
    async get_banner() {
      const res = await get_banner_list()
      this.carouselList = res.data
      console.log('banner', res)
    },
    // 获取新闻列表
    async getNewsLists() {
      const res = await getNewList({
        page_num: 5,
        cate_id: 2,
      })
      this.policies = res.data.data
      this.policies.forEach((item, index) => {
        this.policies[index].create_time = item.create_time.slice(0, 10)
      })
    },
    // 获取新闻列表
    async getNewsLists2() {
      const res = await getNewList({
        page_num: 4,
        cate_id: 1,
      })
      this.dynamicList = res.data.data
      this.dynamicList.forEach((item, index) => {
        this.dynamicList[index].create_time = item.create_time.slice(0, 10)
      })
    },
    // 获取新闻列表
    async getNewsLists3() {
      const res = await getNewList({
        page_num: 4,
        cate_id: 3,
      })
      this.newsList = res.data.data
      this.newsList.forEach((item, index) => {
        this.newsList[index].create_time = item.create_time.slice(0, 10)
      })
    },
    // 鼠标移入加入class
    changeActive($event) {
      $event.currentTarget.className = "active";
    },
    removeActive($event) {
      $event.currentTarget.className = "";
    },
    // 改变轮播
    changeSlide(index) {
      this.$refs.myCarousel.setSlide(index)
      // this.slide = index
    },
    // 跳转更多
    goMore(name) {
      if (name === 'hydt') {
        this.$router.push(`/content/news?index=2&routerindex=0`)
        sessionStorage.setItem('navTitle', '行业动态')
        this.$store.commit('changeIndex', 2)
      } else if (name === 'xmxw') {
        this.$router.push(`/content/news?index=0&routerindex=0`)
        sessionStorage.setItem('navTitle', '项目新闻')
        this.$store.commit('changeIndex', 0)
      } else if (name === 'zcfg') {
        this.$router.push(`/content/news?index=1&routerindex=0`)
        sessionStorage.setItem('navTitle', '政策法规')
        this.$store.commit('changeIndex', 1)
      }
    },
    // 跳转新闻详情
    gonewsInfo(index, name, id) {
      sessionStorage.setItem('routerIndex', 0)
      if (name === 'hydt') {
        sessionStorage.setItem('navTitle', '行业动态')
        this.$router.push(`/content/news/info?id=${id}&index=2&routerindex=0`)
        // this.$store.commit('changeIndex', 2)
      } else if (name === 'xmxw') {
        sessionStorage.setItem('navTitle', '项目新闻')
        this.$router.push(`/content/news/info?id=${id}&index=0&routerindex=0`)
        // this.$store.commit('changeIndex', 0)
      } else if (name === 'zcfg') {
        sessionStorage.setItem('navTitle', '政策法规')
        this.$router.push(`/content/news/info?id=${id}&index=1&routerindex=0`)
        // this.$store.commit('changeIndex', 1)
      }
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  }
}
</script>

<style lang="scss" scoped>
#carousel {
  width: 270px;
  height: 313px;
  overflow: hidden;
  margin-top: 10px;
  margin-left: 10px;
}

#carousel ::v-deep img {
  //width: 100%;
  //height: 100%;
}

.box {
  display: flex;
}

.industryNews {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  .industryNewsBox {
    width: 302px;
    height: 156.5px;

    .title {
      display: inline-block;
      border-bottom: 1px #003366 solid;
      margin: 0;
      padding: 5px 0;
      font-weight: 700;
      color: #003366;
    }

  }
}

.queryBox {
  margin-left: 10px;
}

.indicators {
  width: 270px;
  margin: 0;
  position: absolute;
  background-color: #eeeeeea8;
  bottom: 0;
  left: 10px;
  z-index: 120;
  padding-bottom: 4px;
  text-align: right;

  span {
    cursor: pointer;
    text-align: center;
    margin: 1px 2px;
    color: #fff;
    font-size: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    z-index: 120;
    background-color: rgba(0, 0, 0, 0.575);
    display: inline-block;
  }
}

.changeClass {
  color: #000 !important;
  background-color: rgba(255, 255, 255, 0.575) !important;
}

#carousel-fade {
  //overflow: hidden;
  margin-left: 10px;
  margin-top: 10px;
  width: 580px;
  height: 312px;
  border: 1px #2073dfb4 solid;
}

.imgBox::v-deep img {
  width: 100%;
  //width: 580px;
  //height: 315px;
}

.tabBox {
  border: 1px #2073dfb4 solid;
  width: 250px;
  margin-top: 10px;

  .titleBox {
    text-align: center;
    width: 100%;
    height: 30px;
    background-color: #1c5eb5;

    h3 {
      line-height: 30px;
      color: #fff;
      font-size: 15px;
      font-weight: 400;
    }

  }
}

.tabBox::v-deep .card-header {
  padding: 0 7px;
  padding-right: 5px;
}

.tabBox::v-deep .nav-item {
  background-color: #1c5eb5;
  text-align: center;
  flex: 1 0 auto;
  border: 1px #fff solid;
  border-top: 0;
  border-left: 0;
}

.tabBox::v-deep a {
  font-size: 15px;
  color: #fff;
}

.listBox {
  padding: 5px 10px;
  padding-top: 0;
  border: 0;
}

.list {
  position: relative;
  width: 100%;
  height: 280px;

  .moreBox {
    position: absolute;
    cursor: pointer;
    font-size: 12px;
    bottom: 10px;
    right: 10px;
    color: #7a7c7c
  }

  p {
    font-size: 12px;
    margin: 0;
  }

  .textP1 {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .textP1:hover {
    color: red;
  }

  .textP2 {
    color: #bbbfc9;
  }
}

.card-body {
  padding: 0;
}
</style>
