<template>
  <div id="proTeamList">
    <div class="listBox">
      <div class="title">
        <p>专家团队</p>
      </div>
      <div class="content">
        <div
          class="bookBox"
          v-for="(item, index) in bookList"
          :key="index"
          @click="goBookInfo(item.id)"
        >
          <img :src="item.picture_url" alt="" />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewList } from "@/api/news";
export default {
  data: () => ({
    bookList: [],
  }),
  components: {},
  created() {
    this.getList();
  },
  methods: {
    // 跳转图书详情
    goBookInfo(id) {
      this.$router.push({
        path: "/content/operate/peopleInfo",
        query: {
          id,
        },
      });
    },

    async getList() {
      const res = await getNewList({
        cate_id: 19,
        page_num: 100,
      });
      console.log("res.data", res.data);
      this.bookList = res.data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
#proTeamList {
  margin-top: 10px;
  min-height: 627px;
  border: 1px #2073dfb4 solid;
}

.title {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 17px;
  padding-left: 30px;
}

.content {
  display: flex;
  flex-flow: wrap;

  .bookBox {
    margin: 20px;
    padding: 10px;
    border: 1px #000 solid;
    cursor: pointer;
  }

  img {
    width: 100px;
    height: 110px;
  }

  p {
    font-size: 15px;
    margin: 0;
    margin-top: 10px;
    text-align: center;
  }
}

.bookBox:hover {
  color: red;
}
</style>
