<template>
  <div id="tab">
    <div class="title">
      <p>{{ titleName }}</p>
    </div>
    <div class="tablList" v-if="id !== 'zlxz'">
      <b-list-group class="ListBox">
        <b-list-group-item v-for="(item, index) in list" :key="index" class="itemBox"
                           @click="gonewsInfo(index,item.id,item.picture_url)">
          <p>
            <span v-if="item.file !== ''">
              {{ item.title }}
            </span>
          </p>
        </b-list-group-item>
      </b-list-group>
      <p class="moreBox" @click="gomore" v-if="list.length >= 3 || id =='cjwt'"><span>更多
				<i class="iconfont icon-youjiantou"></i></span></p>
    </div>
    <div class="tablList" v-else>
      <b-list-group class="ListBox">
        <b-list-group-item v-for="(item, index) in list" :key="index" class="itemBox"
                           @click="goCourseContent(item.picture_url,item.title)">
          <p>
            <span v-if="item.file !== ''">
              {{ item.title }}
            </span>
          </p>
        </b-list-group-item>
      </b-list-group>
      <p class="moreBox" @click="gomore" v-if="list.length >= 3 || id =='cjwt'"><span>更多
				<i class="iconfont icon-youjiantou"></i></span></p>
    </div>
  </div>
</template>

<script>
import {downloadFile} from "@/utils/downloadFile";

export default {
  props: ['titleName', "list", 'id'],
  data: () => ({}),
  computed: {},
  created() {
  },
  methods: {
    // 下载文件
    goCourseContent(url, title) {
      console.log(url, title)
      downloadFile(url, title)
    },
    // 跳转详情
    gonewsInfo(index, id, url) {
      if (this.id == 'zcfg') {
        this.$router.push(`/content/news/info?id=${index}&idex=1&routerindex=1`)
        sessionStorage.setItem('routerIndex', 0)
        this.$store.commit('changeIndex', 1)
      } else if (this.id == 'zcfg') {
        // this.$router.push(`/content/news/info?id=${index}`)
        // this.$store.commit('changeIndex', 1)
      } else if (this.id == 'jpkc') {
        this.$router.push(`/content/tech/courseInfo?index=5&routerindex=3&id=${id}`)
        sessionStorage.setItem('navTitle', '精品课程')
        sessionStorage.setItem('routerIndex', 3)
        this.$store.commit('changeIndex', 5)
      } else if (this.id == 'zlxz') {
        window.open(url, '_parent')
      } else if (this.id == 'cjwt') {
        this.$router.push(`/help/problem/info?index=0&id=${id}`)
        sessionStorage.setItem('navTitle', '常见问题')
      }
    },
    // 跳转更多
    gomore() {
      if (this.id == 'zcfg') {
        this.$router.push(`/content/news?index=1`)
        this.$store.commit('changeIndex', 1)
      } else if (this.id == 'zcfg') {
        // this.$router.push(`/content/news/info?id=${index}`)
        // this.$store.commit('changeIndex', 1)
      } else if (this.id == 'jpkc') {
        this.$router.push(`/content/tech?index=5&routerindex=3`)
        sessionStorage.setItem('routerIndex', 3)
        this.$store.commit('changeIndex', 5)
      } else if (this.id == 'zlxz') {
        this.$router.push(`/content/coop?index=5&routerindex=2`)
        sessionStorage.setItem('routerIndex', 2)
        this.$store.commit('changeIndex', 5)
      } else if (this.id == 'cjwt') {
        this.$router.push(`/help/problem?index=0`)
        this.$store.commit('changeIndex', 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#tab {
  width: 249px;
  height: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
}

.ListBox .b-list-group-item {
  width: 249px;
}

.itemBox {
  height: 35px;
}

.tablList {
  box-sizing: border-box;
  min-height: 142px;

  p {
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  p:hover {
    color: red;
  }
}

.linkSpan {
  cursor: pointer;
}

.linkSpan:hover {
  background-color: #007bff;
  color: #ffffff;
}

.moreBox {
  color: #7a7c7c;
  font-size: 12px;
  margin: 7px 0;
  text-align: right;
  padding-right: 10px;

  span {
    cursor: pointer;
  }
}


</style>
