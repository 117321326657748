<template>
  <div id="query">
    <div class="title">
      <p>机构查询</p>
    </div>
    <div class="FormBox">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
        <el-form-item label="机构名称" prop="name">
          <el-input clearable size="small" placeholder="请输入机构名称"
                    v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="机构编号" prop="licence_num">
          <el-input clearable size="small" placeholder="请输入机构编号"
                    v-model="form.licence_num"></el-input>
        </el-form-item>
        <el-form-item class="formItemStyle">
          <el-button size="small" type="primary" @click="submitForm('form')">查询</el-button>
          <el-button size="small" @click="resetForm('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 机构名称查询成功结果 -->
    <el-dialog title="机构查询结果" direction='horizontal' contentStyle="textBoxStyle" center
               :visible.sync="dialogVisible" width="500px">
      <div class="textBox" style="display:flex; justify-content: center">
        <div>
          <p>
					<span>
						机构名称:
					</span>
            <span>
						{{ detailMessage.name }}
					</span>
          </p>
          <p>
					<span>
						资质:
					</span>
            <span>
						{{ detailMessage.merchant_quality }}
					</span>
          </p>
          <p>
					<span>
						机构编号:
					</span>
            <span>
						{{ detailMessage.number }}
					</span>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
    </el-dialog>
    <!-- 为查询到结果 -->
    <el-dialog title="机构查询结果" center :visible.sync="dialogVisible3" width="30%">
      <p style="text-align: center;">{{ tipText }}</p>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
			</span>
    </el-dialog>
  </div>
</template>

<script>
import {
  agencyInfo
} from '../../api/query.js';

export default {
  data: () => ({
    result: 1,
    form: {
      name: '',
      licence_num: ''
    },
    detailMessage: '',
    rules: {
      name: [
        {required: true, message: '请输入机构名称', trigger: 'change'},
      ],
      licence_num: [
        {required: true, message: '请输入机构编号', trigger: 'change'},
      ],
    },
    tipText: '',
    dialogVisible: false,
    dialogVisible3: false
  }),
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = {
            name: this.form.name,
            licence_num: this.form.licence_num,
          };
          await agencyInfo(data).then((res) => {
            if (res.code == 0 && res.data) {
              this.detailMessage = res.data
              this.dialogVisible = true;
            } else {
              this.tipText = res.message;
              this.dialogVisible3 = true;
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style lang="scss" scoped>
#query {
  overflow: hidden;
  width: 249px;
  margin-top: 10px;
  margin-left: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  position: relative;
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
}

.sr-only {
  font-size: 15px;
}

.FormBox {
  margin-top: 5px;
  padding: 10px;
}

.btnBox {
  margin-left: 60px;
  margin-top: 15px;

  .Btn {
    margin: 0 5px;
  }
}

.textBox {
  font-size: 15px;
}

.textBox span:first-child {
  display: inline-block;
  width: 80px;
  text-align: right;
  margin-right: 20px;
}

.textBox span:last-child {
  font-size: 16px;
}

.textInput {
  position: absolute;
  top: 20px;
  left: 10px;
}

.formItemStyle ::v-deep .el-form-item__content {
  float: right;
  margin: 0 !important;
}

.formItemStyle {
  margin: 0;
}
</style>
