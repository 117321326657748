<template>
  <div id="projectContent">
    <h3>{{ newTitle }}</h3>
    <div class="content"
         v-html="newHtml">
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {getNewInfo} from '../../api/news.js'

export default {
  data: () => ({
    index: 0,
    newHtml: '',
    newTitle: '',
  }),
  computed: {
    ...mapState([
      'TabIndex',
      'title'
    ])
  },
  watch: {
    TabIndex() {
      this.index = this.$route.query.index
      this.changeHtml()
    },
    $route: {
      handler() {
        this.index = this.$route.query.index
        this.changeHtml()
      },
      deep: true,
    },
  },
  created() {
    this.index = this.$route.query.index
    this.newTitle = this.title
  },
  mounted() {
    this.index = this.$route.query.index
    this.changeHtml()
  },
  methods: {
    async getInfo(id) {
      const res = await getNewInfo({
        id
      })
      this.newTitle = res.data.title
      this.newHtml = res.data.content
    },
    changeHtml() {
      if (this.index == 0) {
        this.getInfo(69)
      } else if (this.index == 1) {
        this.getInfo(73)
      } else if (this.index == 2) {
        this.getInfo(74)
      } else if (this.index == 3) {
        this.getInfo(75)
      } else if (this.index == 4) {
        this.getInfo(76)
      } else if (this.index == 5) {
        this.getInfo(77)
      } else {
        this.getInfo(78)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#projectContent {
  min-height: 626px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;

  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}

.content {
  padding: 20px;
  padding-top: 0;
}
</style>
