<template>
  <div id="courseList">
    <div class="title">
      <p>{{ title }}</p>
    </div>
    <div class="content">
      <b-list-group>
        <b-list-group-item button v-for="(item,index) in list" :key="index" class="ListBox"
                           @click="goCourseContent(index,item.title,item.id)">
          <span>{{ index + 1 }}</span>
          <h4>{{ item.title }}</h4>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="paginationBox" v-if="total !== 0">
      <!-- <b-pagination v-model="currentPage" :total-rows="rows" align="center"></b-pagination> -->
      <el-pagination class="fengye" @current-change="handleCurrentChange"
                     :current-page="currentPage" :page-size="12"
                     layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex'
import {
  getNewList
} from '../../../api/news.js'

export default {
  props: ['title'],
  data: () => ({
    index: 0,
    rows: 100,
    currentPage: 1,
    list: [],
    total: 0,
  }),
  components: {},
  computed: {
    ...mapState([
      'TabIndex'
    ]),
  },
  created() {
    this.index = this.$route.query.index
    this.getLists()
  },
  mounted() {
  },
  methods: {
    //改变页数
    handleCurrentChange(value) {
      this.currentPage = value
    },
    // 获取新闻列表
    async getLists() {
      const res = await getNewList({
        page_num: 14,
        current_page: this.currentPage,
        cate_id: 10
      })
      this.list = res.data.data
      this.total = res.data.total
    },
    // 跳转课程详情
    goCourseContent(index, name, id) {
      this.$router.push(`/content/tech/courseInfo?id=${id}&index=${this.index}&routerindex=3`)
      sessionStorage.setItem('navTitle', this.title)
    },
  }

}
</script>
<style lang="scss" scoped>
#courseList {
  margin-top: 10px;
  position: relative;
  height: 637px;
  border: 1px #2073dfb4 solid;
}

.title {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 17px;
  padding-left: 20px;
}

.ListBox {
  height: 35px;
  display: flex;

  h4 {
    margin-left: 20px;
    line-height: 22px;
    width: 650px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.paginationBox {
  position: absolute;
  bottom: 20px;
  margin-top: 10px;
  right: 20px;
}

.content {
  height: 525px;
  overflow: hidden;
}
</style>
