<template>
  <div id="content">
    <div class="newsList" v-if="IsshowNewsList">
      <div class="title">
        <!-- <b-breadcrumb class="navBox" :items="navItem"></b-breadcrumb> -->
        <!-- <p>{{title}}</p> -->
        <b-breadcrumb class="navBox">
          <b-breadcrumb-item href="#foo">{{ title }}</b-breadcrumb-item>
          <!-- <b-breadcrumb-item active>Baz</b-breadcrumb-item> -->
        </b-breadcrumb>
      </div>
      <div class="content" v-if="IsShow">
        <List :index="TabIndex"/>
      </div>
    </div>
    <div class="newsInfo" v-if="IsshowNewsInfo">
      <NewsInfo/>
    </div>
    <!-- 		<div class="projectFile" v-if="IsShowProject">
      <ProjectFile />
    </div> -->
    <div v-if="IsShowProjectInfo">
      <ProjectInfo :title="title"/>
    </div>
    <div v-if="IsShowTechSystem">
      <TechSystem :title="title"/>
    </div>
    <div v-if="IsShowTextbookList">
      <TextbookList :title="title"/>
    </div>
    <div v-if="IsShowTextbookContent">
      <TextbookContent/>
    </div>
    <div v-if="IsShowCourseList">
      <courseList :title="title"/>
    </div>
    <div v-if="IsShowCourseInfo">
      <courseInfo :title="title"/>
    </div>
    <div v-if="IsShowTrainTestContent">
      <TrainTestContent :title="title"/>
    </div>
    <div v-if="IsShowOpePraContent">
      <OpePraContent :title="title"/>
    </div>
    <div v-if="IsShowProTeamList">
      <ProTeamList :title="title"/>
    </div>
    <div v-if="IsShowProTeamInfo">
      <ProTeamInfo :title="title"/>
    </div>
    <div v-if="IsShowOperatPracticeList">
      <OperatPracticeList :title="title"/>
    </div>
    <div v-if="IsShowInstitutions">
      <institutions :title="title"/>
    </div>
    <div v-if="IsScoopModelContent">
      <coopModelContent :title="title"/>
    </div>
    <div v-if="IsOnlineTeach">
      <onlineTeach :title="title"/>
    </div>
    <div v-if="IsOnlineTeachContent">
      <onlineTeachContent :title="title"/>
    </div>
    <div v-if="IsloginRegister">
      <loginRegister :title="title"/>
    </div>
  </div>
</template>

<script>
import {
  getNav
} from '../../api/nav'
import {
  mapState
} from 'vuex'
import List from './list.vue'
import NewsInfo from './newsInfo.vue'
// import ProjectFile from '../project/project_file.vue'
import ProjectInfo from '../project/project_content.vue'
import TechSystem from '../TechSystem/TechContent.vue'
import TextbookContent from '../TechSystem/textbook/textbookContent.vue'
import TextbookList from '../TechSystem/textbook/textbookList.vue'
import courseList from '../TechSystem/course/courseList.vue'
import courseInfo from '../TechSystem/course/courseContent.vue'
import TrainTestContent from '../trainTest/trainTestContent.vue'
import OpePraContent from '../OperatPractice/OpePraContent.vue'
import ProTeamList from '../OperatPractice/ProTeam/proTeamList.vue'
import ProTeamInfo from '../OperatPractice/ProTeam/proTeamInfo.vue'
import OperatPracticeList from '../OperatPractice/OperatPracticeList.vue'
import institutions from '../coopModel/institutions.vue'
import coopModelContent from '../coopModel/content.vue'
import onlineTeach from '../teacherHall/list.vue'
import onlineTeachContent from '../teacherHall/content.vue'
import loginRegister from '../teacherHall/LoginRegister.vue'

export default {
  props: ['routerName'],
  data: () => ({
    navList: [],
    list: [],
    title: '',
    TabNum: 0,
  }),
  components: {
    List,
    NewsInfo,
    // ProjectFile,
    ProjectInfo,
    TechSystem,
    TextbookList,
    TextbookContent,
    courseList,
    courseInfo,
    TrainTestContent,
    OpePraContent,
    ProTeamList,
    ProTeamInfo,
    OperatPracticeList,
    institutions,
    coopModelContent,
    onlineTeach,
    onlineTeachContent,
    loginRegister
  },
  computed: {
    ...mapState([
      'TabIndex'
    ]),
    IsShow: function () {
      if (this.$route.path == '/content/news') {
        return true
      } else {
        return false
      }
    },
    IsshowNewsList() {
      if (this.$route.path == '/content/news') {
        return true
      } else {
        return false
      }
    },
    IsshowNewsInfo() {
      if (this.$route.path == '/content/news/info') {
        return true
      } else {
        return false
      }
    },
    // IsShowProject() {
    // 	if (this.$route.path == '/content/project' ) {
    // 		return true
    // 	} else {
    // 		return false
    // 	}
    // },
    IsShowProjectInfo() {
      if (this.$route.path == '/content/project') {
        return true
      } else {
        return false
      }
    },
    IsShowTechSystem() {
      if (this.$route.path == '/content/tech' && this.index !== 4 && this.index !== 5) {
        return true
      } else {
        return false
      }
    },
    IsShowTextbookList() {
      if (this.$route.path == '/content/tech' && this.index === 4) {
        return true
      } else {
        return false
      }
    },
    IsShowTextbookContent() {
      if (this.$route.path == '/content/tech/info') {
        return true
      } else {
        return false
      }
    },
    IsShowCourseList() {
      if (this.$route.path == '/content/tech' && this.index === 5) {
        return true
      } else {
        return false
      }
    },
    IsShowCourseInfo() {
      if (this.$route.path == '/content/tech/courseInfo' && this.index === 5) {
        return true
      } else {
        return false
      }
    },
    IsShowTrainTestContent() {
      if (this.$route.path == '/content/train') {
        return true
      } else {
        return false
      }
    },
    IsShowOpePraContent() {
      if (this.$route.path == '/content/operate' && this.index !== 3 && this.index !== 5) {
        return true
      } else {
        return false
      }
    },
    IsShowProTeamList() {
      if (this.$route.path == '/content/operate' && this.index == 3) {
        return true
      } else {
        return false
      }
    },
    IsShowProTeamInfo() {
      if (this.$route.path == '/content/operate/peopleInfo') {
        return true
      } else {
        return false
      }
    },
    // IsShowOperatPracticeList() {
    //   if (this.$route.path == '/content/operate' && this.index == 5) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    IsShowOperatPracticeList() {
      if (this.$route.path == '/content/coop' && this.index == 5) {
        return true
      } else {
        return false
      }
    },
    IsShowInstitutions() {
      if (this.$route.path == '/content/coop' && this.index == 4) {
        return true
      } else {
        return false
      }
    },
    IsScoopModelContent() {
      if (this.$route.path == '/content/coop' && this.index !== 4 && this.index !== 5) {
        return true
      } else {
        return false
      }
    },
    IsOnlineTeach() {
      if (this.$route.path == '/content/online' && this.index !== 3) {
        return true
      } else {
        return false
      }
    },
    IsOnlineTeachContent() {
      if (this.$route.path == '/content/online/info' && this.index !== 3) {
        return true
      } else {
        return false
      }
    },
    IsloginRegister() {
      if (this.$route.path == '/content/online' && this.index == 3) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    routerName(newValue) {
      this.navList.forEach(item => {
        if (item.title == newValue) {
          this.list = item.list
          this.title = item.list[0].title
          sessionStorage.setItem('navTitle', this.title)
        }
      })
    },
    TabIndex(newVal) {
      this.title = this.list[newVal].title
      sessionStorage.setItem('navTitle', this.title)
      this.index = this.$route.query.index - 0
    }
  },
  created() {
    this.index = this.$route.query.index - 0
  },
  mounted() {
    this.getNavList(this.index)
  },
  methods: {
    // 获取导航列表
    async getNavList(index) {
      const res = await getNav()
      this.navList = res
      this.navList.forEach(item => {
        if (item.title == this.routerName) {
          this.list = item.list
          this.title = item.list[index].title
        }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.title {
  margin-top: 10px;
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 17px;
  padding-left: 20px;
}

.navBox::v-deep .breadcrumb-item {
  color: #dddddd;
}

.navBox::v-deep a {
  color: #fff;
  text-decoration: none;
}
</style>
