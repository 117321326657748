import http from '../utils/common'

//合作机构列表
export function getCoopList(data) {
    var url = '/api/agency/list'
    var type = 'post'
    var params = {}
    if (data) {
        params = data
    }
    return http.api(url, params, type)
}