<template>
	<div id="footer">
		<p>
			<span v-for="(item,index) in link" :key="index" class="linkSpan"
				@click="goHelp(item.index,item.title)">{{item.title}}</span>
		</p>
		<p style="margin-top:7px">
			全国通信和信息技术创新人才培养工程 版权所有
			<span class="linkSpan"><a target="_blank"
					href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备15057234号-3</a></span>
		</p>
	</div>
</template>

<script>
	export default {
		data: () => ({
			link: [{
					title: "关于我们",
					index: 1
				},
				{
					title: "联系我们",
					index: 2

				},
				{
					title: "招贤纳士",
					index: 4

				},
				{
					title: "乘车路线",
					index: 5
				},
			]
		}),
		methods: {
			goHelp(index, title) {
				this.$router.push(`/help/use?index=${index}`)
				this.$store.commit('changeTitle', title)
				this.$store.commit('changeIndex', index)
			}
		}
	}
</script>

<style lang="scss" scoped>
	#footer {
		padding-top: 5px;
		width: 100%;
		bottom: 0;
		height: 60px;
		background-color: #cccccc;
		p {
			margin: 0;
			text-align: center;
			font-size: 14px;
		}
	}

	.linkSpan {
		cursor: pointer;
		margin: 0 7px;
	}

	.linkSpan:hover {
		color: red;
	}

	a {
		color: black;
		text-decoration: none;
	}
</style>
