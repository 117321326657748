<template>
  <div id="app">
    <div>
      <div id="nav">
        <p class="text">
          <span>欢迎访问CIIT网站</span> |
          <span>{{ data }}</span>
        </p>
      </div>
      <div class="body">
        <Header/>
        <router-view/>
      </div>
    </div>
    <div class="footerBox">
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from "@/components/global/header.vue"
import Footer from "@/components/global/footer.vue"

export default {
  components: {
    Header,
    Footer
  },
  data: () => ({
    data: ''
  }),
  created() {
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      var nowDate = new Date();
      var year = nowDate.getFullYear();
      var month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) :
          nowDate.getMonth() + 1;
      var day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
          .getDate();
      this.data = year + "年" + month + "月" + day + "日";
    }
  }
}
</script>
<style lang="scss">
@import url("./assets/css/global.scss");
@import url('//at.alicdn.com/t/font_2776347_fsg4daf4fxu.css');

#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#nav {
  background-color: rgba(236, 232, 232, 0.459);

  .text {
    width: 1100px;
    margin: 0 auto;
    color: #999;
    font-size: 15px;
  }
}

.body {
  width: 1100px;
  margin: 0 auto;
  height: 100%;
}

.footerBox {

}

.is-active .el-carousel__button {
  background-color: black !important;
}

//轮播图小圆点
.el-carousel__button {
  background: #888686 !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}

.btn-light {
  border: 1px black solid !important;
  background: white !important;
}

// 地图标点大小
img {
  max-width: inherit !important;
}
</style>
