<template>
  <div class="home">
    <div class="notice">
      <Rolling/>
    </div>
    <div class="boxs">
      <div class="box">
        <FirstBox/>
      </div>
      <div style="margin-top: 10px; cursor: pointer;">
        <img src="../assets/img/centerbanner2.jpg"
             alt="" @click="goPromote(1)" class="imgStyle" />
      </div>
      <div class="box">
        <SecondBox/>
      </div>
      <div class="box">
        <ThirdBox/>
      </div>
      <div style="margin-top: 10px; cursor: pointer;">
        <img src="../assets/img/centerbanner.gif"
             alt="" @click="goPromote(0)">
      </div>
      <div class="box">
        <FourthBox/>
      </div>
      <div class="box">
        <NewAddHomeItem/>
      </div>
      <div class="box">
        <FifthBox/>
      </div>
      <div class="box">
        <sixthBox/>
      </div>
    </div>
  </div>
</template>

<script>
import Rolling from '../components/home/rolling.vue'
import FirstBox from '../components/home/firstBox.vue'
import SecondBox from '../components/home/secondBox.vue'
import ThirdBox from '../components/home/thirdBox.vue'
import FourthBox from '../components/home/fourthBox.vue'
import FifthBox from '../components/home/fifthBox.vue'
import SixthBox from '../components/home/sixthBox.vue'
import NewAddHomeItem from '../components/home/newAddHomeItem.vue'

export default {
  components: {
    Rolling,
    FirstBox,
    SecondBox,
    ThirdBox,
    FourthBox,
    FifthBox,
    SixthBox,
    NewAddHomeItem
  },
  data: () => ({
    Laws: "政策法规",
    LawsList: [
      {title: "创新培养纳入本科教学质量国家标准"},
      {title: "智慧城市的国家标准与评价体系"},
      {title: "职业教育最需要引起关注的五个问题"},
      {title: "鼓励探索SIEAM教育、创客教育等教育模式"},
      {title: "李克强部署加快发展现代职业教育"},
      {title: "李克强部署加快发展现代职业教育"},
      {title: "郑重声明"},
    ],
    newsList: [{
      'title': '关于通信和信息技术创新人才培养工程项目证书改版的通知'
    }, {
      'title': '经中国通信工业协会通信和信息技术创新人才培养工程项目办公室审核通过，第一期智慧消防工程师考试时间定于2020年8月15日'
    }, {
      'title': '热烈祝贺国开在线教育科技有限公司成为电大行业管理中心'
    }
    ],
    carouselList: [
      {
        title: '我是标题1',
        img: './lunboImag/1.png',
      },
      {
        title: '我是标题2',
        img: './lunboImag/2.png',
      },
      {
        title: '我是标题3',
        img: './lunboImag/3.jpg',
      },
      {
        title: '我是标题4',
        img: './lunboImag/4.png',
      },
      {
        title: '我是标题5',
        img: './lunboImag/5.png',
      }
    ],
    carouselValue: 0,
    slide: 0,
    sliding: null
  }),
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2
      }
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    goPromote() {
      // this.$router.push(`/promote?index=${index}`)
      this.$router.push(`/content/online?index=0&routerindex=6`)
    }
  }
}
</script>

<style lang="scss" scoped>
.imgStyle {
  width: 1100px;
}
</style>
