<template>
  <div id="OpePraContent">
    <h3>{{ NewTitle }}</h3>
    <div class="content" v-html="newHtml">
    </div>
  </div>
</template>

<script>
import {
  getNewInfo
} from '../../api/news'
import {
  mapState
} from 'vuex'

export default {
  props: ['title'],
  data: () => ({
    index: 0,
    newHtml: '',
    NewTitle: ''
  }),
  computed: {
    ...mapState([
      'TabIndex'
    ])
  },
  watch: {
    TabIndex() {
      this.index = this.$route.query.index
      this.changeHTML()
    }
  },
  created() {
    this.index = this.$route.query.index
    this.changeHTML()
  },
  mounted() {
  },
  methods: {
    // 获取详情
    async getInfo(id) {
      const res = await getNewInfo({
        id
      })
      this.NewTitle = res.data.title
      this.newHtml = res.data.content
    },
    changeHTML() {
      if (this.index == 0) {
        this.getInfo(19)
        // this.newHTML = this.html1
      } else if (this.index == 1) {
        this.getInfo(20)
        // this.newHTML = this.html2
      } else if (this.index == 2) {
        this.getInfo(21)
        // this.newHTML = this.html3
      } else if (this.index == 4) {
        this.getInfo(22)
        // this.newHTML = this.html3
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#OpePraContent {
  min-height: 584px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;

  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}

.content {
  padding: 20px;
}
</style>
