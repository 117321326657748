<template>
  <div id="textbookContent">
    <div class="navtitle">
      <b-breadcrumb class="navBox">
        <b-breadcrumb-item @click="goNewsList">{{ navTitle }}</b-breadcrumb-item>
        <b-breadcrumb-item active>详情</b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <div class="title">
      <h3>{{ info.title }}</h3>
    </div>
    <div class="content" v-html="info.content">
    </div>
  </div>
</template>

<script>
import {getNewInfo} from '../../../api/news.js'

export default {
  data: () => ({
    navTitle: '',
    index: 0,
    title: '',
    info: {
      title: '',
      content: ''
    }
  }),
  created() {
    this.title = sessionStorage.getItem('courseName')
    this.index = this.$route.query.index
    this.getinfo()
  },
  mounted() {
    this.navTitle = sessionStorage.getItem('navTitle')
  },
  methods: {
    goNewsList() {
      this.$router.push('/content/tech?index=5&routerindex=3')
    },
    async getinfo() {
      const res = await getNewInfo({
        id: this.$route.query.id
      })
      this.info = res.data
    }
  }
}
</script>

<style scoped lang="scss">
#textbookContent {
  min-height: 626px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}

.content {
  padding: 20px;
  padding-top: 0;
}

.navtitle {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 17px;
  padding-left: 20px;
}

.navBox::v-deep .breadcrumb-item {
  color: #dddddd;
}

.navBox::v-deep a {
  color: #fff;
  text-decoration: none;
}
</style>
