<template>
	<div id="tab">
		<div class="title">
			<p>实训平台</p>
		</div>
		<div class="tablList">
			<b-list-group>
				<b-list-group-item v-for="(item,index) in list" :key="index" class="linkSpan" :active="active === index"
					@click="changeList(index,item.title)">
					<span>
						{{item.title}}
					</span>
				</b-list-group-item>
			</b-list-group>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			navList: [],
			list: [{
					title: '培训说明'
				},
				{
					title: '培训大纲'
				},
				{
					title: '在线报名'
				},
				{
					title: '在线学习'
				},
				{
					title: '在线考试'
				}
			],
			active: 0,
			routerIndex: 0,
			title: ''
		}),
		watch: {
			$route: {
				handler() {
					this.active = this.$route.query.index - 0
				},
				deep: true,
			}
		},
		computed: {},
		created() {
			this.active = this.$route.query.index - 0
			this.changeTitle()
			this.changeList(this.active, this.title)
		},
		mounted() {},
		methods: {
			// title改变
			changeTitle() {
				if (this.active === 0) {
					this.title = '培训说明'
				} else if (this.active === 1) {
					this.title = '培训大纲'
				} else if (this.active === 2) {
					this.title = '在线报名'
				} else if (this.active === 3) {
					this.title = '在线学习'
				} else if (this.active === 4) {
					this.title = '在线考试'
				}
			},
			// tab切换
			changeList(index, title) {
				this.$router.push(`/trainTest?index=${index}`)
				this.active = index
				this.$store.commit('changeIndex', index)
				this.$store.commit('changeTitle', title)
			}
		}
	}
</script>

<style lang="scss" scoped>
	#tab {
		width: 249px;
		margin-top: 10px;
		border: 1px #2073dfb4 solid;
	}

	.title {
		background-color: #1c5eb5;
		width: 100%;
		height: 30px;
		color: #ffffff;
		text-align: center;
		line-height: 30px;
		font-size: 17px;
	}

	.tablList {
		box-sizing: border-box;
		padding: 15px;
	}

	.linkSpan {
		cursor: pointer;
	}

	.linkSpan:hover {
		background-color: #007bff;
		color: #ffffff;
	}
</style>
