import http from '../utils/common'

export async function subject(data) {
    // return data
    var url = 'date/subjectv2.json'
    var type = 'get'
    var params = {}
    if (data) {
        params = data
    }
    return http.json(url, params, type)
}