import { render, staticRenderFns } from "./project_content.vue?vue&type=template&id=35806bf4&scoped=true&"
import script from "./project_content.vue?vue&type=script&lang=js&"
export * from "./project_content.vue?vue&type=script&lang=js&"
import style0 from "./project_content.vue?vue&type=style&index=0&id=35806bf4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35806bf4",
  null
  
)

export default component.exports