<template>
  <div id="projectContent">
    <div class="navtitle">
      <b-breadcrumb class="navBox">
        <b-breadcrumb-item @click="goNewsList">
          {{
            $route.query.index == 0
              ? "学科名称"
              : $route.query.index == 1
              ? "水平证书"
              : $route.query.index == 2
              ? "专家讲座"
              : ""
          }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active>详情</b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- <h3>{{ newTitle }}</h3> -->
    <Info />
    <!-- <div class="content" v-html="newHtml"></div> -->
  </div>
</template>

<script>
import Info from "./info.vue";
import { getNewInfo } from "../../api/news.js";
// import {
//   mapState
// } from 'vuex'

export default {
  components: {
    Info,
  },
  data: () => ({
    index: 0,
    newHtml: "",
    newTitle: "",
    path: "",
  }),
  computed: {},
  watch: {},
  created() {
    this.look();
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getInfo(this.id);
  },
  methods: {
    look() {
      console.log("newTitle", this.newTitle);
    },
    goNewsList() {
      this.$router.push(
        `/content/online?index=${this.$route.query.index}&routerindex=6`
      );
    },
    async getInfo(id) {
      const res = await getNewInfo({ id });
      this.newHtml = res.data.content;
      this.newTitle = res.data.title;
    },
  },
};
</script>

<style lang="scss" scoped>
#projectContent {
  min-height: 626px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;

  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}

.content {
  padding: 20px;
  padding-top: 0;
}

.navtitle {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 17px;
  padding-left: 20px;
}

.navBox::v-deep .breadcrumb-item {
  color: #dddddd;
}

.navBox::v-deep a {
  color: #fff;
  text-decoration: none;
}
</style>
