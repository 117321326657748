import axios from 'axios'

export const downloadFile = (url, filename) =>
    axios.get(url, {
        responseType: 'blob',
    }).then((res) => {
        let fileClass = ''
        if(url.indexOf('.xls') !== -1) {
            fileClass = '.xls'
        }else if(url.indexOf('.doc') !== -1) {
            fileClass = '.doc'
        }
        const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'}) // 构造一个blob对象来处理数据，并设置文件类型
        const href = URL.createObjectURL(blob) // 创建新的URL表示指定的blob对象
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = href // 指定下载链接
        a.download = filename + fileClass // 指定下载文件名
        a.click()
        URL.revokeObjectURL(a.href) // 释放URL对象
        // if (window.navigator.msSaveOrOpenBlob) {
        //     // 兼容IE10
        //     navigator.msSaveBlob(blob, filename + '.xls')
        // } else {
        //     const href = URL.createObjectURL(blob) // 创建新的URL表示指定的blob对象
        //     const a = document.createElement('a')
        //     a.style.display = 'none'
        //     a.href = href // 指定下载链接
        //     a.download = filename + '.xls' // 指定下载文件名
        //     a.click()
        //     URL.revokeObjectURL(a.href) // 释放URL对象
        // }
    })
