<template>
  <div id="tab">
    <div class="title">
      <p>{{ titleName }}</p>
    </div>
    <div class="tablList">
			<span v-for="(item) in areaList" :key="item.code" @click="gpCoop(item.code)">
				{{ item.name }}
			</span>
    </div>
    <p class="moreBox" @click="gpCoop2"><span>更多
				<i class="iconfont icon-youjiantou"></i></span></p>
  </div>
</template>

<script>
import {getArea} from '../../api/news.js'

export default {
  props: ['titleName'],
  data: () => ({
    areaList: []
  }),
  computed: {},
  created() {
  },
  mounted() {
    this.getAreaList()
  },
  methods: {
    //获取地区列宾
    async getAreaList() {
      const res = await getArea()
      this.areaList = res.data.slice(0, 16)
      console.log(res)
    },
    // 跳转
    gpCoop(code) {
      this.$router.push(`/content/coop?code=${code}&index=4&routerindex=2`)
      this.$store.commit('changeIndex', 3)
    },
    gpCoop2() {
      this.$router.push('/content/coop?index=3&routerindex=2')
      this.$store.commit('changeIndex', 3)
    }
  }
}
</script>

<style lang="scss" scoped>
#tab {
  width: 249px;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
  margin-bottom: 10px;
}

.ListBox .b-list-group-item {
  width: 249px;
}

.itemBox {
  height: 35px;
}

.linkSpan {
  cursor: pointer;
}

.linkSpan:hover {
  background-color: #007bff;
  color: #ffffff;
}

.moreBox {
  margin-top: 10px;
  color: #7a7c7c;
  font-size: 12px;
  text-align: right;
  padding-right: 10px;

  span {
    cursor: pointer;
  }
}

.tablList {
  box-sizing: border-box;
  font-size: 14px;
}

.tablList span {
  margin: 15px;
  cursor: pointer;
}

.tablList span:hover {
  color: red;
}
</style>
