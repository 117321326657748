<template>
  <div id="training">
    <div class="titleBox">
      <div class="title">
        <p>{{ title }}</p>
      </div>
    </div>
    <div class="centern" v-if="name == 'pxks'">
      <img src="../../assets/img/xyfu.jpg" alt="" class="leftImg" />
      <div class="contentBox">
        <!--        <img :src="item.img"-->
        <!--             alt=""-->
        <!--             v-for="(item,index) in list"-->
        <!--             :key="index"-->
        <!--             class="rightImg"-->
        <!--             @click="goTrainInfo(index)">-->
        <div
          class="content_item"
          v-for="(item, index) in list"
          :key="index"
          @click="goTrainInfo(index)"
        >
          <img :src="item.img" class="rightImg2" />
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
    <div v-else class="centern">
      <img src="../../assets/img/zlhz.jpg" alt="" class="leftImg2" />
      <div class="contentBox">
        <div
          class="content_item"
          v-for="(item, index) in list2"
          :key="index"
          @click="goZlhz(index)"
        >
          <img :src="item.img" class="rightImg2" />
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "name"],
  data: () => ({
    list: [
      {
        img: "./testImg/考核标准.png",
        title: "培训标准",
      },
      {
        img: "./testImg/精确准确.png",
        title: "考场规则",
      },
      {
        img: "./testImg/开放.png",
        title: "考试流程",
      },
      {
        img: "./testImg/search.png",
        title: "成绩查询",
      },
      {
        img: "./testImg/专业权威.png",
        title: "就业服务",
      },
      {
        img: "./testImg/certified-supplier.png",
        title: "优秀学员",
      },
    ],
    list2: [
      {
        img: "./testImg/合同.png",
        title: "合作介绍",
      },
      {
        img: "./testImg/丰富多元.png",
        title: "合作流程",
      },
      {
        img: "./testImg/印章认证.png",
        title: "合作标准",
      },
      {
        img: "./testImg/真实可信.png",
        title: "行业风采",
      },
      {
        img: "./testImg/真实可信.png",
        title: "行业风采",
      },
      {
        img: "./testImg/真实可信.png",
        title: "行业风采",
      },
    ],
    zlhzList: [
      {
        title: "合作介绍",
      },
      {
        title: "合作流程",
      },
      {
        title: "合作标准",
      },
      {
        title: "行业风采",
      },
      {
        title: "行业风采",
      },
      {
        title: "行业风采",
      },
    ],
  }),
  methods: {
    goTrainInfo(index) {
      this.$router.push(`/content/train?index=${index}&routerindex=4`);
      sessionStorage.setItem("routerIndex", 4);
      this.$store.commit("changeIndex", index);
    },
    goZlhz(index) {
      if (index == 0) {
        this.$router.push(`/content/coop?index=1&routerindex=2`);
      } else if (index == 1) {
        this.$router.push(`/content/coop?index=0&routerindex=2`);
      } else if (index == 2) {
        this.$router.push(`/content/coop?index=2&routerindex=2`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#training {
  margin-top: 10px;
  margin-left: 10px;
  width: 582px;
  height: 100%;
  border: 1px #2073dfb4 solid;
  border-top: none;
  background-color: #f9f9f9;
}

.titleBox {
  position: relative;
  width: 100%;
  height: 30px;
  background-color: #e7e5e5;

  .title {
    position: relative;
    height: 0;
    width: 180px;
    border-top: 30px solid #1c5eb5;
    border-right: 52px solid transparent;

    p {
      color: #ffffff;
      position: absolute;
      top: -27px;
      left: 38px;
    }
  }

  .moreBox {
    cursor: pointer;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 5px;
  }
}

.centern {
  box-sizing: border-box;
  padding-top: 15px;
  display: flex;

  .contentBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .content_item {
      cursor: pointer;
      line-height: 37px;
      width: 120px;
      height: 37px;
      margin: 0 10px;
      border: 1px #e7e5e5 solid;
      font-size: 14px;
    }
  }

  .leftImg {
    width: 120px;
    margin: 20px 10px;
    margin-right: 0;
  }

  .rightImg2 {
    width: 25px;
    margin-left: 10px;
    margin-right: 7px;
    margin-bottom: 6px;
  }

  .leftImg2 {
    width: 100px;
    height: 108px;
    margin: 10px;
    margin-left: 20px;
  }
}

.iconfont {
  font-size: 14px;
  margin-right: 10px;
}

.rightImg {
  cursor: pointer;
  margin: 10px;
  width: 120px;
}

.itemBoxs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 19px;

  .itemBox {
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    font-size: 15px;
    margin-top: 0;
    margin: 10px;
    width: 120px;
    padding: 8px 10px;
    background-color: #e9e9e9;
  }
}
</style>
