<template>
	<div id="userHelp">
		<b-row>
			<b-col cols="3">
				<Tab />
				<Query />
			</b-col>
			<b-col cols="9">
				<Content />
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import Tab from '../../components/trainPlatform/tab.vue'
	import Query from '../../components/global/query.vue'
	import Content from '../../components/trainPlatform/content.vue'
	export default {
		components: {
			Tab,
			Query,
			Content
		},
		data: () => ({

		})
	}
</script>
<style scoped lang="scss">
	#userHelp {
		margin-bottom: 10px;
	}
</style>
