<template>
  <div class="content">
    <div class="navImg">
      <img src="../../assets/img/topbanner.png" alt="" />
    </div>
    <div class="nav">
      <div class="xialaBoxs" @click="goHone">首页</div>
      <div v-for="(item, index) in navList" :key="index">
        <el-dropdown class="xialaBoxs" size="medium" @command="goContent">
          <div class="el-dropdown-link">
            {{ item.title }}
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item2, index2) in item.list"
              class="xialaBox"
              :key="index2"
              :command="{ name: item.router, index: index2, index2: index }"
            >
              {{ item2.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!--      <b-dropdown lazy class="xialaBoxs" :text="item.title" variant="light" v-for="(item,index) in navList"-->
      <!--                  :key="index">-->
      <!--        <b-dropdown-item class="xialaBox swing_flip_1" href="#" v-for="(item2,index2) in item.list"-->
      <!--                         :key="index2" @click="goContent(item.router,index2,index)">{{ item2.title }}-->
      <!--        </b-dropdown-item>-->
      <!--      </b-dropdown>-->
    </div>
  </div>
</template>

<script>
import { getNav } from "../../api/nav";

export default {
  data: () => ({
    navList: [],
  }),
  mounted() {
    this.getNavList();
    this.$root.$on("bv::dropdown::show", (bvEvent) => {
      console.log("Dropdown is about to be shown", bvEvent);
    });
  },
  methods: {
    // 跳转主页
    goHone() {
      this.$router.push("/");
    },
    // 跳转
    goContent(data) {
      console.log(123456);
      console.log("data", data);

      this.$router.push(
        `/content/${data.name}?index=${data.index}&routerindex=${data.index2}`
      );
      // if (name === 'project' && index !== 0) {
      //   this.$router.push(`/content/${name}/proInfo`)
      // } else {
      //   this.$router.push(`/content/${name}`)
      // }
      this.$store.commit("changeIndex", data.index);
    },
    // 获取导航列表
    async getNavList() {
      const res = await getNav();
      this.navList = res;
      console.log("this.navList", this.navList);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.navBox {
  margin-top: 8px;
  display: flex;

  h2 {
    font-weight: 700;
    width: 2em;
    margin: 0 10px;
    font-size: 20px;
    color: #93130e;
  }

  .linkBoxs {
    width: 110px;
    height: 70px;
    font-size: 13px;
    margin-right: 5px;
  }

  .linkBoxs span {
    cursor: pointer;
    margin: 5px 0;
  }

  .linkBoxs span:hover {
    color: blue;
  }
}

.shouye {
  cursor: pointer;
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 15px;
  width: 1em;
  font-size: 20px;
  font-weight: 700;
  color: #93130e;
}

.navImg {
  img {
    width: 100%;
  }
}

.homeBtn {
  margin-top: 7px;
  height: 40px;
  width: 120px;
}

.xialaBoxs {
  cursor: pointer;
  box-sizing: border-box;
  margin-top: 7px;
  width: 130px;
  height: 40px;
  color: #ffffff;
  background: #1c5eb5;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
}

.xialaBox {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 130px;
  font-size: 13px !important;
  margin-bottom: 10px;
}
</style>
