<template>
  <div class="box">
    <div class="tebBox">
      <div class="tab">
        <div class="title2">
          <p>指导单位</p>
        </div>
        <div class="imgBox">
          <a target="_blank" href="http://www.miit.gov.cn/n11293472/index.html">
            <img src="../../assets/img/zgdwgxb.jpg" alt="" />
          </a>
        </div>
      </div>
      <div class="tab">
        <div class="title2">
          <p>主管单位</p>
        </div>
        <div class="imgBox">
          <a href="http://www.ccia.org.cn/">
            <img src="../../assets/img/CCIA.jpg" alt="" />
          </a>
        </div>
      </div>
    </div>
    <div class="zhanjia" style="display: none">
      <div class="title">
        <p>名师讲堂</p>
      </div>
      <div class="zjltBox">
        <img src="../../assets/img/zjlt.png" alt="" @click="goTeacherHall" />
      </div>
    </div>

    <div class="teamBox">
      <Team :title="teamTitle" />
    </div>
    <div class="courseBox">
      <tab :titleName="title2" :list="courseList" :id="'zlxz'" />
    </div>
  </div>
</template>

<script>
import { getNewList } from "../../api/news";
import tab from "../tab/homeTab.vue";
import Team from "../home/team.vue";

export default {
  components: {
    tab,
    Team,
  },
  data: () => ({
    teamTitle: "专家团队",
    title2: "资料下载",
    courseList: [],
    list: [
      {
        img: "./icon/jpkc.jpg",
        router: "",
      },
      {
        img: "./icon/pxsm.jpg",
        router: "",
      },
      {
        img: "./icon/pxdg.jpg",
        router: "",
      },
      {
        img: "./icon/zxbm.jpg",
        router: "",
      },
      {
        img: "./icon/zxxx.jpg",
        router: "",
      },
      {
        img: "./icon/zxks.jpg",
        router: "",
      },
    ],
  }),
  created() {
    this.getcourse();
  },
  methods: {
    //  获取精品课程
    async getcourse() {
      const res = await getNewList({
        page_num: 5,
        cate_id: 21,
      });
      this.courseList = res.data.data;
    },
    goTeacherHall() {
      this.$router.push(`/content/online?index=0&routerindex=6`);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
}

.zjltBox {
  img {
    cursor: pointer;
    width: 140px;
    height: 165px;
    margin-top: 20px;
  }
}

.courseBox {
  margin-left: 10px;
  height: 245px;
}

.title {
  text-align: center;
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
}

.zhanjia {
  width: 250px;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.tebBox {
  margin-right: 10px;
}
.tab {
  margin-top: 10px;
  width: 249px;
  height: 117.5px;
  border: 1px #2073dfb4 solid;

  .title2 {
    text-align: center;
    background-color: #1c5eb5;
    width: 100%;
    height: 30px;
    color: #ffffff;
    text-align: center;
    line-height: 30px;
    font-size: 17px;
  }

  .imgBox {
    margin-top: 17px;
    cursor: pointer;
    text-align: center;
    line-height: 50px;
  }
}
</style>
