<template>
  <div id="list">
    <div class="titleBox">
      <p>
        {{
          $route.query.index == 0
            ? "学科名称"
            : $route.query.index == 1
            ? "水平证书"
            : $route.query.index == 2
            ? "专家讲座"
            : ""
        }}
      </p>
    </div>

    <div class="ListBoxs">
      <div style="width: 819px; padding: 20px">
        <el-row :gutter="20">
          <div
            class="formBox"
            v-for="(item, index) in list"
            :key="index"
            @click="goNewsInfo(index, item, list)"
          >
            <el-col :span="8">
              <div class="subjectBox">
                <div class="imgBox">
                  <img
                    :src="
                      $route.query.index == 0 ? item.img : item.certificateImg
                    "
                    :alt="item.title"
                  />
                  <div v-if="$route.query.index == 0" class="imgText">
                    <p
                      style="
                        margin-bottom: 0;
                        text-align: center;
                        font-size: 16px;
                      "
                    >
                      <b>{{ item.title }}</b>
                    </p>
                  </div>
                </div>
                <div class="textBox">
                  <p>
                    <b>{{ item.title }}</b>
                  </p>
                </div>
              </div>
            </el-col>
          </div>
          <div v-if="$route.query.index == 2">
            <p>内容审核中...</p>
            <p>咨询 刘主任 18701377690 15810657608</p>
          </div>
        </el-row>
      </div>
    </div>
    <div class="paginationBox" v-if="$route.query.index != 2">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="page"
        :page-size="size"
        :page-sizes="pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { subject } from "../../api/subject";
export default {
  data: () => ({
    index: "",
    page: 1, //第几页
    size: 12, //一页多少条
    total: 0, //总条目数
    pageSizes: [12, 24, 48],

    list: [],
  }),
  computed: {
    title() {
      let index = this.$route.query.index;
      let title =
        index == 0 ? "学科名称" : index == 1 ? "证书名称" : "课程名称";

      return title;
    },
  },
  mounted() {
    this.index = this.$route.query.index;
  },
  created() {
    this.getSubject();
  },
  watch: {
    $route() {
      this.index = this.$route.query.index;
      this.getSubject();
      this.getPage();
    },
  },
  methods: {
    getPage() {
      (this.page = 1), (this.size = 12);
    },
    //page改变时的回调函数，参数为当前页码
    currentChange(val) {
      this.page = val;
      console.log("val", val);

      this.getSubject();
    },
    //size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      this.size = val;
      console.log("val", val);

      this.page = 1;
      this.getSubject();
    },

    /**
     * 根据路由参数给list数组赋值. 0赋值第一层数组；1赋值第二层certificate中的数据；2赋值三层course中的数据
     * @returns{arr}
     */
    async getSubject() {
      const res = await subject();

      let index = this.$route.query.index;
      if (index == 0) {
        this.list = res.slice(
          (this.page - 1) * this.size,
          this.page * this.size
        );
        console.log("this.page", this.page);

        this.total = res.length;
      } else if (index == 1) {
        const certificate_list = [];
        for (let i = 0; i < res.length; i++) {
          let a = res[i].certificate;
          if (a) {
            for (let j = 0; j < a.length; j++) {
              certificate_list.push(a[j]);
            }
          }
        }
        this.list = certificate_list.slice(
          (this.page - 1) * this.size,
          this.page * this.size
        );
        console.log("this.page", this.page);

        this.total = certificate_list.length;
      } else if (index == 2) {
        this.list = [];
      }
    },

    // 跳转详情
    goNewsInfo(index, item, list) {
      console.log("list", list);

      const arr = [];
      if (this.index == 2) {
        console.log("this.list", this.list);
        this.list.forEach((i) => {
          if (item.course_id == i.course_id) {
            arr.push(i);
          }
        });
      }

      if (!item.certificate && this.index == 0) {
        this.$message({
          type: "warning",
          message: "学科暂没开放",
        });
      } else {
        this.$router.push({
          path: "/content/online/info",
          query: {
            id: item.id,
            index: this.index,
            subject_img: item.img,
            info: item,
            arr,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#list {
  margin-top: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px #2073dfb4 solid;
}

.subjectBox {
  box-shadow: 1px 1px 3px 3px #c1c1c1;
  margin-bottom: 20px;
  height: 180px;
  cursor: pointer;
}

.textBox {
  padding: {
    left: 20px;
    right: 20px;
    top: 10px;
  }

  p {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

img {
  width: 100%;
  height: 100%;
}

.imgBox {
  height: 80%;
  width: 100%;
  position: relative;
}

.imgText {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 20px;
  color: #fff;
  width: 100%;
}
.ListBoxs {
  min-height: 476px;
}
.paginationBox {
  position: absolute;
  bottom: 0;
  right: 20px;
  margin-top: 10px;
}

.titleBox {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 17px;
  padding-left: 20px;
}
</style>
