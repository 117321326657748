<template>
  <div id="textbookContent">
    <div class="title">
      <h3>{{ title }}</h3>
    </div>
    <div class="content">
      <p v-html="bookInfo"></p>
    </div>
  </div>
</template>

<script>
import { getNewInfo } from "@/api/news";
export default {
  data: () => ({
    title: "",
    bookInfo: "",
  }),
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const res = await getNewInfo({
        id: this.$route.query.id,
      });
      this.title = res.data.title;
      this.bookInfo = res.data.content;
    },
  },
};
</script>

<style scoped lang="scss">
#textbookContent {
  min-height: 544px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}
.title {
  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}
.content {
  padding: 20px;
  padding-top: 0;
}
</style>