<template>
  <div id="textbookContent">
    <div class="title">
      <h3>{{ title }}</h3>
    </div>
    <div class="content"
         v-html="newHTML">
    </div>
  </div>
</template>

<script>
import {getNewInfo} from '../../api/news'
import {mapState} from 'vuex'

export default {
  // props: ['title'],
  data: () => ({
    newHTML: '',
    title: '',
    index: '',
  }),
  computed: {
    ...mapState([
      'TabIndex'
    ])
  },
  watch: {
    TabIndex() {
      this.index = this.$route.query.index
      this.changeHtml()
    }
  },
  created() {
  },
  mounted() {
    this.index = this.$route.query.index
    this.changeHtml()
  },
  methods: {
    // 获取详情
    async getInfo(id) {
      const res = await getNewInfo({
        id
      })
      this.title = res.data.title
      this.newHTML = res.data.content
    },
    changeHtml() {
      if (this.index == 0) {
        this.getInfo(13)
        // this.newHTML = this.html1
      } else if (this.index == 1) {
        this.getInfo(14)
        // this.newHTML = this.html2
      } else if (this.index == 2) {
        this.getInfo(15)
        // this.newHTML = this.html3
      } else if (this.index == 3) {
        this.getInfo(16)
        // this.newHTML = this.html5
      } else if (this.index == 4) {
        // this.newHTML = ''
        this.getInfo(17)
      } else if (this.index == 5) {
        // this.newHTML = ''
        this.getInfo(18)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#textbookContent {
  min-height: 626px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}

.content {
  padding: 20px;
  padding-top: 0;
}
</style>
