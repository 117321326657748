<template>
	<div id="training">
		<div class="titleBox">
			<div class="title">
				<p>{{title}}</p>
			</div>
			<p class="moreBox" @click="goMore"><span>更多
					<i class="iconfont icon-youjiantou"></i></span></p>
		</div>
		<div class="centern">
			<img @click="goInfo(index)" v-for="(item,index) in list" :key="index" :src="item.img" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		props: ['title', 'list', 'name'],
		data: () => ({
			titleName: ''
		}),
		methods: {
			goMore() {
				console.log(this.name)
				if (this.name == 'sxpt') {
					this.$router.push(`/trainPlat?index=0`)
				}else if(this.name == 'pxks') {
					this.$router.push(`/trainTest?index=0`)
				}
			},
			goInfo(active) {
				if (this.name == 'sxpt') {
					this.$router.push(`/trainPlat?index=${active}`)
				}else if(this.name == 'pxks') {
					if(active === 0) {
						this.$router.push(`/content/tech?index=5`)
						this.$store.commit('changeIndex',5)
					}else {
						this.$router.push(`/trainTest?index=${active - 1}`)
					}
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	#training {
		margin-top: 10px;
		margin-left: 10px;
		width: 582px;
		height: 221px;
		border: 1px #2073dfb4 solid;
		border-top: none;
		background-color: #f9f9f9;
	}

	.titleBox {
		position: relative;
		width: 100%;
		height: 30px;
		background-color: #e7e5e5;

		.title {
			position: relative;
			height: 0;
			width: 180px;
			border-top: 30px solid #1c5eb5;
			border-right: 52px solid transparent;

			p {
				color: #ffffff;
				position: absolute;
				top: -27px;
				left: 38px;
			}
		}

		.moreBox {
			cursor: pointer;
			font-size: 12px;
			position: absolute;
			right: 0;
			top: 5px;
		}
	}

	.centern {
		text-align: center;

		img {
			margin: 20px;
			cursor: pointer;
		}
	}

	.iconfont {
		font-size: 14px;
		margin-right: 10px;
	}
</style>
