<template>
  <div id="box">
    <div class="tabBox">
      <div class="centerBox">
        <div class="title">
          <p>支持单位</p>
        </div>
        <div class="imgBack">
          <a target="_blank" :href="item.url" v-for="(item,index) in list" :key="index">
            <img :src="item.img" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    list: [{
      img: './unitImg/g1.png',
      url: 'http://www.gcvaic.org/'
    },
      {
        img: './unitImg/g2.png',
        url: 'http://www.ncie.gov.cn/'
      },
      {
        img: './unitImg/g3.png',
        url: 'http://www.ccia.org.cn/'
      },
      {
        img: './unitImg/g4.png',
        url: 'http://www.cnscn.com.cn/'
      },
    ]
  }),
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
#box {
  margin: 10px 0;
  width: 1100px;
  height: 120px;
  display: flex;
}

.tabBox {
  width: 1100px;
}

.title {
  position: relative;
  height: 0;
  width: 180px;
  border-top: 30px solid #1c5eb5;
  border-right: 52px solid transparent;

  p {
    color: #ffffff;
    position: absolute;
    top: -27px;
    left: 38px;
  }
}

.imgBack {
  width: 100%;
  display: flex;
  justify-content: space-between;

  img {
    margin-top: 10px;
    cursor: pointer;
    width: 220px;
    //height: 40px;
  }
}

.centerBox {
  border: 1px #2073dfb4 solid;
  height: 120px;
}
</style>
