import http from '../utils/common'

export async function getNav (data) {
  // return data
  var url = 'date/nav.json'
  var type = 'get'
  var params = {}
  if (data) {
    params = data
  }
  return http.json(url, params, type)
}