import http from '../utils/common'

export function studentsInfo(data) {
  var url = '/api/students/info'
  var type = 'get'
  var params = {}
  if (data) {
    params = data
  }
  return http.api(url, params, type)   
}

export function agencyInfo(data) {
  var url = '/api/agency/info'
  var type = 'get'
  var params = {}
  if (data) {
    params = data
  }
  return http.api(url, params, type)
}