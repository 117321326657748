<template>
  <div id="tab">
    <div class="title">
      <p>{{ routerName ? routerName : routerName2 }}</p>
    </div>
    <div class="tablList">
      <b-list-group>
        <b-list-group-item v-for="(item,index) in list" :key="index" class="linkSpan" :active="active === index"
                           @click="changeList(index,item.title)">
					<span>
						{{ item.title }}
					</span>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import {
  getNav
} from '../../api/nav'
import {
  mapState
} from 'vuex'

export default {
  props: ['routerName'],
  data: () => ({
    index: 0,
    navList: [],
    list: [],
    active: 0,
    routerIndex: 0,
    routerName2: '',
    routerindex: '',
  }),
  watch: {
    routerName(newValue) {
      this.navList.forEach(item => {
        if (item.title == newValue) {
          this.list = item.list
        }
      })
      console.log('this.list', this.list)
    },
    TabIndex(newVal) {
      this.active = newVal
      this.index = this.$route.query.index - 0
      this.active = this.index
      this.getNavList()
    }
  },
  computed: {
    ...mapState([
      'TabIndex'
    ])
  },
  created() {
    this.getNavList()
    this.index = this.$route.query.index - 0
    this.active = this.index
    this.routerindex = this.$route.query.routerindex
    console.log('this.routerindex', this.routerindex)
  },
  mounted() {

  },
  methods: {
    // 切换Tab栏
    changeList(index, title) {
      this.navList.forEach(item => {
        item.list.forEach(item2 => {
          if (item2.title === title) {
            this.$router.push(`/content/${item.router}?index=${index}&routerindex=${this.routerindex}`)
          }
        })
      })
      this.active = index
      this.$store.commit('changeIndex', index)
    },
    // 获取导航列表
    async getNavList() {
      let index2 = this.$route.query.index
      const routerIndex = this.$route.query.routerindex
      if (routerIndex) {
        index2 = routerIndex
      }
      console.log(this.$route.path)
      const res = await getNav()
      this.navList = res
      if (index2) {
        const index = index2
        this.list = this.navList[index].list
        this.routerName2 = this.navList[index].title
      }
      this.navList.forEach((item, index) => {
        if (item.title == this.routerName) {
          this.list = item.list
          this.routerIndex = index
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#tab {
  width: 249px;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
}

.tablList {
  box-sizing: border-box;
  padding: 15px;
}

.linkSpan {
  cursor: pointer;
}

.linkSpan:hover {
  background-color: #007bff;
  color: #ffffff;
}
</style>
