<template>
  <div id="newInfo">
    <div class="navtitle">
      <b-breadcrumb class="navBox">
        <b-breadcrumb-item @click="goNewsList">{{ navTitle }}</b-breadcrumb-item>
        <b-breadcrumb-item active>详情</b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <div class="title">
      <h3>{{ info.title }}</h3>
      <p>{{ info.create_time ? info.create_time : create_time }}</p>
    </div>
    <div class="content" v-html="info.content">
    </div>
  </div>
</template>

<script>
import {getNewInfo} from '../../api/news.js'

export default {
  data: () => ({
    index: 0,
    id: 0,
    navTitle: '',
    create_time: '',
    info: {
      title: '',
      content: '',
      release_time: ''
    }
  }),
  created() {
    this.id = this.$route.query.id - 0
    this.index = this.$route.query.index - 0
  },
  mounted() {
    this.navTitle = sessionStorage.getItem('navTitle')
    this.create_time = sessionStorage.getItem('create_time')
    this.getInfo(this.id)
    console.log('this.create_time', this.create_time)
  },
  methods: {
    //获取详情
    async getInfo(id) {
      const res = await getNewInfo({
        id
      })
      this.info = res.data
    },
    goNewsList() {
      this.$router.push(`/content/news?index=${this.index}&routerindex=0`)
    }
  }
}
</script>

<style lang="scss" scoped>
#newInfo {
  min-height: 544px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  box-sizing: border-box;
  padding: 20px;
  text-align: center;

  h3 {
    padding: 10px 0;
    border-bottom: 3px #000 solid;
    font-size: 23px;
  }

  p {
    font-size: 14px;
    color: #b1b1b1;
  }
}

.content {
  padding: 20px;
  padding-top: 0;
}

.navtitle {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 17px;
  padding-left: 20px;
}

.navBox::v-deep .breadcrumb-item {
  color: #dddddd;
}

.navBox::v-deep a {
  color: #fff;
  text-decoration: none;
}
</style>
