<template>
  <div id="query">
    <div class="title">
      <!--      <p>推荐教材</p>-->
      <p>培训考试</p>
    </div>
    <!--    <div class="imageBox">-->
    <!--      <img :src="img" @click="goZxxx"/>-->
    <!--    </div>-->
    <div class="FormBox">
      <el-form :model="form" :rules="rules" ref="form" label-width="55px" class="demo-ruleForm">
        <el-form-item label="手机号" prop="name">
          <el-input clearable size="small" placeholder="请输入手机号"
                    v-model="form.Org_name"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="id_num">
          <el-input clearable size="small" placeholder="请输入登陆密码"
                    v-model="form.Org_id"></el-input>
        </el-form-item>
        <el-form-item style="margin: 0;">
          <span class="fontStyle">登陆后可以报名学习</span>
          <div class="buttonBoxStyle">
            <el-button size="small" type="primary" @click="submitForm('ruleForm')">登陆</el-button>
            <el-button size="small" @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="查询结果" center :visible.sync="dialogVisible3" width="30%">
      <p style="text-align: center;">培训考试平台暂未开放</p>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible3 = false">确 定</el-button>
			</span>
    </el-dialog>
    <!--    <div class="box">-->
    <!--      <el-carousel class="contentBoxs" indicator-position="none">-->
    <!--        <el-carousel-item v-for="(item,index) in list" :key="index" class="contentBoxs">-->
    <!--          <div class="contentBox" @click="goBookInfo(index)">-->
    <!--            <img :src="item.img" alt="">-->
    <!--            <p>{{ item.title }}</p>-->
    <!--          </div>-->
    <!--        </el-carousel-item>-->
    <!--      </el-carousel>-->
    <!--      <p class="moreBox" @click="goMore"><span>更多-->
    <!--					<i class="iconfont icon-youjiantou"></i></span></p>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  data: () => ({
    dialogVisible3:false,
    form: {},
    rules: {},
    list: [{
      title: '云计算',
      img: './materialImg/book1.png'
    },
      {
        title: '互联网',
        img: './materialImg/book2.png'
      }
    ],
    img: './icon/zxjy.png'
  }),
  methods: {
    submitForm() {
      this.dialogVisible3 = true
    },
    resetForm() {
      this.form.Org_name = '';
      this.form.Org_id = '';
    },
    goZxxx() {
      this.$router.push('trainTest?index=3')
    },
    // 跳转更多
    goMore() {
      this.$router.push('/content/tech?index=4')
      this.$store.commit('changeIndex', 4)
    },
    // 跳转详情
    goBookInfo(index) {
      this.$router.push(`/content/tech/info?id=${index}&routerindex=3&index=4`)
      // sessionStorage.setItem('routerIndex', 3)
      this.$store.commit('changeIndex', 4)
    }
  }
}
</script>

<style lang="scss" scoped>
#query {
  overflow: hidden;
  width: 249px;
  height: 221px;
  margin-top: 10px;
  margin-left: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
}

.contentBoxs ::v-deep .el-carousel__container {
  height: 180px !important;
}

.contentBox {
  cursor: pointer;
  text-align: center;
  height: 180px;
  padding: 20px;

  img {
    width: 90px;
    height: 120px;
  }
}

.box {
  position: relative;

  .moreBox {
    position: absolute;
    bottom: -20px;
    right: 0;
    color: #7a7c7c;
    font-size: 12px;
    text-align: right;
    cursor: pointer;
    z-index: 1200;
  }

  .contentBox:hover {
    color: red;
  }
}

.imageBox {
  cursor: pointer;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 180px;
  }
}

.FormBox {
  margin-top: 5px;
  padding: 10px;
}

.btnBox {
  margin-left: 60px;
  //margin-top: 15px;

  .Btn {
    margin: 0 5px;
  }
}

.demo-ruleForm {
  position: relative;
}

.buttonBoxStyle {
  position: absolute;
  right: 0;
  bottom: -25px;
}

.fontStyle {
  position: absolute;
  bottom: -55px;
  right: 5px;
  color: #888686;
  font-size: 10px;
  float: right;
}
</style>
