<template>
  <div id="textbookContent">
    <div class="title">
      <h3>{{title}}</h3>
    </div>
    <div class="content">
      <img :src="bookInfo"
           alt="">
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    title: '',
    index: '',
    bookInfo: '',
    bookInfolist: [
      {
        img: './textBookImg/bookContent1.png'
      },
      {
        img: './textBookImg/bookContent2.png'
      },
      {
        img: './textBookImg/bookContent3.png'
      },
      {
        img: './textBookImg/bookContent4.png'
      },
    ]
  }),
  created () {
    this.title = sessionStorage.getItem('bookname')
    this.index = this.$route.query.id
    this.changeBoox(this.index)
    console.log('this.index', this.index)
  },
  methods: {
    changeBoox (index) {
      this.bookInfo = this.bookInfolist[index].img
      console.log(this.bookInfo)

    }
  }
}
</script>

<style scoped lang="scss">
#textbookContent {
  min-height: 544px;
  width: 100%;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}
.title {
  h3 {
    margin: 30px 0;
    font-size: 20px;
    text-align: center;
  }
}
.content {
  padding: 20px;
  padding-top: 0;
}
</style>