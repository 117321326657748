<template>
  <div class="box">
    <div class="tabBox">
      <div class="titleBox">
        <h3>项目新闻</h3>
      </div>
      <div class="list">
        <b-list-group>
          <b-list-group-item v-for="(item,index) in newsList" :key="index" class="listBox"
                             @click="gonewsInfo(index,'xmxw',item.id)">
            <p class="textP2">
              <i class="iconfont icon-shijian" style="color: #ff9f5b;"></i>
              {{ item.create_time }}
            </p>
            <p class="textP1">
              {{ item.title }}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p class="moreBox" @click="goMore('xmxw')" v-if="newsList.length >=2">
          <span>更多<i class="iconfont icon-youjiantou"></i></span></p>
      </div>
    </div>
    <div class="training">
      <Training :title="title2"
                :list="list" :name="'pxks'"/>
    </div>

    <div class="query">
      <Material/>
    </div>
  </div>
</template>

<script>
import Material from '../home/material.vue'
import Training from '../home/training.vue'
import {getNewList} from "@/api/news";

export default {
  components: {
    Training,
    Material
  },
  data: () => ({
    title2: "在线教育",
    newsList: [],
    cityList: [
      {title: "北京"},
      {title: "天津"},
      {title: "上海"},
      {title: "重庆"},
      {title: "吉林"},
      {title: "辽宁"},
      {title: "河南"},
      {title: "山东"},
      {title: "山西"},
      {title: "陕西"},
      {title: "安徽"},
      {title: "江苏"},
      {title: "四川"},
      {title: "贵州"},
      {title: "福建"},
    ],
    list: [{
      img: './icon/jpkc.jpg',
      router: ''
    },
      {
        img: './icon/pxsm.jpg',
        router: ''
      },
      {
        img: './icon/pxdg.jpg',
        router: ''
      },
      {
        img: './icon/zxbm.jpg',
        router: ''
      },
      {
        img: './icon/zxxx.jpg',
        router: ''
      }, {
        img: './icon/zxks.jpg',
        router: ''
      }
    ],
  }),
  computed: {},
  mounted() {
    this.getNewsLists3()
  },
  methods: {
    // 获取新闻列表
    async getNewsLists3() {
      const res = await getNewList({
        page_num: 3,
        cate_id: 3,
      })
      this.newsList = res.data.data
      this.newsList.forEach((item, index) => {
        this.newsList[index].create_time = item.create_time.slice(0, 10)
      })
    },
    // 跳转更多
    goMore(name) {
      if (name === 'hydt') {
        this.$router.push(`/content/news?index=2&routerindex=0`)
        sessionStorage.setItem('navTitle', '行业动态')
        this.$store.commit('changeIndex', 2)
      } else if (name === 'xmxw') {
        this.$router.push(`/content/news?index=0&routerindex=0`)
        sessionStorage.setItem('navTitle', '项目新闻')
        this.$store.commit('changeIndex', 0)
      } else if (name === 'zcfg') {
        this.$router.push(`/content/news?index=1&routerindex=0`)
        sessionStorage.setItem('navTitle', '政策法规')
        this.$store.commit('changeIndex', 1)
      }
    },
    // 跳转新闻详情
    gonewsInfo(index, name, id) {
      sessionStorage.setItem('routerIndex', 0)
      if (name === 'hydt') {
        sessionStorage.setItem('navTitle', '行业动态')
        this.$router.push(`/content/news/info?id=${id}&index=2&routerindex=0`)
        // this.$store.commit('changeIndex', 2)
      } else if (name === 'xmxw') {
        sessionStorage.setItem('navTitle', '项目新闻')
        this.$router.push(`/content/news/info?id=${id}&index=0&routerindex=0`)
        // this.$store.commit('changeIndex', 0)
      } else if (name === 'zcfg') {
        sessionStorage.setItem('navTitle', '政策法规')
        this.$router.push(`/content/news/info?id=${id}&index=1&routerindex=0`)
        // this.$store.commit('changeIndex', 1)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#carousel {
  width: 270px;
  height: 313px;
  overflow: hidden;
  margin-top: 10px;
  margin-left: 10px;
}

#carousel ::v-deep img {
  width: 100%;
  height: 100%;
}

.box {
  display: flex;
}

.industryNews {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  .industryNewsBox {
    width: 302px;
    height: 156.5px;

    .title {
      display: inline-block;
      border-bottom: 1px #003366 solid;
      margin: 0;
      padding: 5px 0;
      font-weight: 700;
      color: #003366;
    }

    .list {
      width: 100%;
      border-top: 1px #003366 solid;
    }
  }
}

.queryBox {
  margin-left: 10px;
}
.listBox {
  padding: 5px 10px;
  padding-top: 0;
  border: 0;
}
.tabBox {
  border: 1px #2073dfb4 solid;
  width: 250px;
  margin-top: 10px;

  .titleBox {
    text-align: center;
    width: 100%;
    height: 30px;
    background-color: #1c5eb5;

    h3 {
      line-height: 30px;
      color: #fff;
      font-size: 15px;
      font-weight: 400;
    }

  }
}

.tabBox::v-deep .card-header {
  padding: 0 7px;
  padding-right: 5px;
}

.tabBox::v-deep .nav-item {
  background-color: #1c5eb5;
  text-align: center;
  flex: 1 0 auto;
  border: 1px #fff solid;
  border-top: 0;
  border-left: 0;
}

.tabBox::v-deep a {
  font-size: 15px;
  color: #fff;
}

.list {
  position: relative;
  width: 100%;
  height: 187px;

  .moreBox {
    position: absolute;
    cursor: pointer;
    font-size: 12px;
    bottom: 10px;
    right: 10px;
    color: #7a7c7c
  }

  p {
    font-size: 12px;
    margin: 0;
  }

  .textP1 {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .textP1:hover {
    color: red;
  }

  .textP2 {
    color: #bbbfc9;
  }
}

</style>
