<template>
  <div id="courseList">
    <div class="title">
      <p>{{ title }}</p>
    </div>
    <div class="content">
      <b-list-group>
        <b-list-group-item
          button
          v-for="(item, index2) in list"
          :key="index2"
          class="ListBox"
          @click="goCourseContent(item.picture_url, item.title)"
        >
          <a> {{ item.title }}</a>
          <p>{{ item.create_time }}</p>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="paginationBox" v-if="total !== 0">
      <el-pagination
        class="fengye"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNewList } from "../../api/news";

import { mapState } from "vuex";
import { downloadFile } from "@/utils/downloadFile";

export default {
  props: ["title"],
  data: () => ({
    index: "",
    current_page: "",
    rows: 100,
    currentPage: 1,
    total: 0,
    list: [],
    successList: [],
  }),
  components: {},
  computed: {
    ...mapState(["TabIndex"]),
  },
  watch: {
    TabIndex() {
      this.changeList();
      this.index = this.$route.query.index;
    },
  },
  created() {
    this.index = this.$route.query.index;
    this.changeList();
  },
  mounted() {},
  methods: {
    goCourseContent(url, title) {
      downloadFile(url, title);
    },
    goDownload(id) {
      console.log(id);
    },
    handleCurrentChange(current_page) {
      this.current_page = current_page;
      this.changeList();
    },

    async getNewsLists() {
      const res = await getNewList({
        current_page: this.current_page,
        page_num: 15,
        cate_id: 21,
      });
      this.list = res.data.data;
      this.total = res.data.total;
    },
    // 更新新闻列表
    changeList() {
      if (this.index == 5) {
        this.getNewsLists();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#courseList {
  margin-top: 10px;
  position: relative;
  height: 627px;
  border: 1px #2073dfb4 solid;
}

.title {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  font-size: 17px;
  padding-left: 30px;
}

.ListBox {
  display: flex;
  justify-content: space-between;
  height: 35px;

  a {
    color: #000000;
    text-decoration: none;
  }

  h4 {
    width: 650px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.paginationBox {
  position: absolute;
  bottom: 20px;
  margin-top: 10px;
  right: 20px;
}

.content {
  height: 525px;
  overflow: hidden;
}
</style>
