<template>
  <div id="list">
    <div class="titleBox">
      <h3>{{ title }}</h3>
    </div>
    <div class="ListBoxs">
      <b-list-group>
        <b-list-group-item button v-for="(item,index) in list" :key="index" class="ListBox"
                           @click="goNewsInfo(item.id)">
          <span>{{ index + 1 }}</span>
          <h4>{{ item.title }}</h4>
          <!-- <p>{{item.release_time}}</p> -->
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="paginationBox" v-if="total !== 0">
      <!-- <b-pagination v-model="currentPage" :total-rows="rows" align="center"></b-pagination> -->
      <el-pagination class="fengye" @current-change="handleCurrentChange"
                     :current-page="current_page" :page-size="15" layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getNewList
} from '../../api/news'
import {
  mapState
} from 'vuex'

export default {
  data: () => ({
    total: 0,
    rows: 100,
    current_page: 1,
    list: [],
    index: '',
    title: '常见问题'
  }),
  computed: {
    ...mapState([
      'TabIndex',
    ]),
  },
  created() {
    this.getNewsLists()
  },
  mounted() {
    this.index = this.$route.query.index
  },
  watch: {},
  methods: {
    handleCurrentChange(current_page) {
      this.current_page = current_page
      this.getNewsLists()
    },
    // 获取新闻列表
    async getNewsLists() {
      const res = await getNewList({
        current_page: this.current_page,
        page_num: 15,
        cate_id: 24,
      })
      this.list = res.data.data
      this.total = res.data.total
    },
    goNewsInfo(id) {
      this.$router.push(`/help/problem/info?index=${this.index}&id=${id}`)
      sessionStorage.setItem('navTitle', this.title)
    }
  }
}
</script>
<style lang="scss" scoped>
#list {
  position: relative;
  width: 100%;
  height: 620px;
}

.ListBoxs {
  width: 819px;
  padding-right: 2px;
}

.ListBox {
  display: flex;
  height: 35px;

  h4 {
    margin-left: 20px;
    width: 650px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.paginationBox {
  position: absolute;
  bottom: 20px;
  margin-top: 10px;
  right: 20px;
}

.titleBox {
  padding-left: 20px;
  color: #fff;
  width: 100%;
  height: 30px;
  background-color: #1c5eb5;

  h3 {
    line-height: 30px;
    font-size: 18px;
  }
}
</style>
