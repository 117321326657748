import { render, staticRenderFns } from "./secondBox.vue?vue&type=template&id=ac5ca78a&scoped=true&"
import script from "./secondBox.vue?vue&type=script&lang=js&"
export * from "./secondBox.vue?vue&type=script&lang=js&"
import style0 from "./secondBox.vue?vue&type=style&index=0&id=ac5ca78a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac5ca78a",
  null
  
)

export default component.exports