<template>
  <div class="rolling">
    <div class="notice">
      <div>
        <i class="iconfont icon-laba1 noticeIcon"/>
      </div>
      <vueSeamless :data="newsList"
                   :class-option="optionLeft"
                   class="seamless-warp2">
        <ul class="item">
          <a class="hoverUrl" href="javascript:;" v-for="(item,index) in newsList"
             v-text="item.title"
             :key="index" @click="goInfo(item.id)"></a>
        </ul>
      </vueSeamless>
    </div>
  </div>
</template>

<script>
import vueSeamless from 'vue-seamless-scroll'
import {getNewList} from '../../api/news.js'

export default {
  components: {
    vueSeamless,
  },
  data: () => ({
    newsList: [{
      title: "经中国通信工业协会通信和信息技术创新人才培养工程项目办公室审核通过，第一期智慧消防工程师考试时间定于2020年8月15日。"
    }],
  }),
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2
      }
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    goInfo(id){
      sessionStorage.setItem('navTitle', '项目新闻')
      this.$router.push(`/content/news/info?id=${id}&index=0&routerindex=0`)
    },
    async getList() {
      const res = await getNewList({
        cate_id: 5, page_num: 10, current_page: 1
      })
      this.newsList = res.data.data
      console.log('rsdfsdfsdfes',res)
    },
  }
}
</script>

<style lang="scss" scoped>
.noticeIcon {
  color: #fee042;
  font-size: 25px;
}

.notice {
  display: flex;
  flex-direction: row;
}

.seamless-warp2 {
  overflow: hidden;
  height: 35px;
  width: 100%;

  ul.item {
    margin: 0;
    width: 100%;
    line-height: 35px;

    a {
      text-decoration: none;
      font-size: 12px;
      color: red;
    }
  }
}
.hoverUrl {
  margin: 0 30px;
}
</style>
