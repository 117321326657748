import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Content from '../views/index/index.vue'
import Help from '../views/index/useHelp.vue'
import TrainPlatform from '../views/index/trainPlatform.vue'
import TrainTest from '../views/index/trainTest.vue'
import Promote from '../views/index/promote.vue'
import TeacherHall from '../views/index/teacherHall.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // children: [
    //   { path: '/index',
    //   component: UserHome
    // }
    // ]
  },
  {
    path: '/content/*',
    name: 'content',
    component: Content,
  },
  {
    path: '/help/*',
    name: 'Help',
    component: Help,
  },
  {
    path: '/trainPlat',
    name: 'TrainPlatform',
    component: TrainPlatform,
  },
  {
    path: '/trainTest',
    name: 'TrainTest',
    component: TrainTest,
  },
  {
    path: '/promote',
    name: 'Promote',
    component: Promote,
  },
  {
    path: '/teacherHall/*',
    name: 'TeacherHall',
    component: TeacherHall,
  }
]

const router = new VueRouter({
  // mode: 'history',  //去掉url中的#
  routes
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return routerPush.call(this, location, onResolve, onReject);
  return routerPush.call(this, location).catch(error => error)
};
export default router
