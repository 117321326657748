<template>
  <div id="training">
    <div class="titleBox">
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <p class="moreBox" @click="goMore">
        <span>更多 <i class="iconfont icon-youjiantou"></i></span>
      </p>
    </div>
    <div class="teacherBox">
      <el-carousel :interval="4000" type="card" height="150px">
        <el-carousel-item v-for="(item, index) in bookList" :key="index">
          <div class="imgBox">
            <div style="width: 120px" @click="goteacherInfo(item.id)">
              <img :src="item.picture_url" class="imgStyle" />
              <h4 class="medium">{{ item.title }}</h4>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>

      <!-- <el-carousel
        loop
        indicator-position="none"
        ref="carousel"
        height="245px"
        :initial-index="0"
        arrow="always"
      >
        <el-carousel-item
          class="imgBox"
          v-for="(item, index) in newList"
          :key="index"
        >
          <div
            class="teacher"
            v-for="(item2, index2) in item"
            :key="index2"
            @click="goteacherInfo(index)"
          >
            <img :src="item2.img" class="imgStyle" />
            <p style="margin-top: 10px">{{ item2.teacherName }}</p>
          </div>
        </el-carousel-item>
      </el-carousel> -->
    </div>
  </div>
</template>

<script>
import { getNewList } from "@/api/news";

export default {
  components: {},
  props: ["title", "list"],
  data: () => ({
    slide: 0,

    bookList: [],
  }),
  mounted() {
    // this.changeArray();
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const res = await getNewList({
        cate_id: 19,
        page_num: 100,
      });
      console.log("res.data", res.data);
      this.bookList = res.data.data;
    },
    // changeArray() {
    //   let num = 3;
    //   for (var i = 0; i < this.teacherList.length / 3; i++) {
    //     this.newList.push(this.teacherList.slice(num * i, num * (i + 1)));
    //   }
    //   // this.newList = this.teacherList
    //   console.log("this.newList", this.newList);
    // },
    // 跳转专家详情
    goteacherInfo(id) {
      this.$router.push({
        path: "/content/operate/peopleInfo?index=3&routerindex=5",
        query: {
          id,
        },
      });
    },
    // 更多跳转
    goMore() {
      this.$router.push(`/content/operate?index=3`);
      this.$store.commit("changeIndex", 3);
    },
  },
};
</script>

<style lang="scss" scoped>
#training {
  margin-top: 10px;
  //margin-left: 10px;
  width: 582px;
  height: 245px;
  border: 1px #2073dfb4 solid;
  border-top: none;
}

.titleBox {
  position: relative;
  width: 100%;
  height: 30px;
  background-color: #e7e5e5;

  .title {
    position: relative;
    height: 0;
    width: 180px;
    border-top: 30px solid #1c5eb5;
    border-right: 52px solid transparent;

    p {
      color: #ffffff;
      position: absolute;
      top: -27px;
      left: 38px;
    }
  }

  .moreBox {
    cursor: pointer;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 5px;
  }
}

.iconfont {
  font-size: 14px;
  margin-right: 10px;
}

.centern {
}

.teacher {
  cursor: pointer;

  p {
    text-align: center;
  }
}

img {
  margin-right: 20px;
}

p {
  margin-bottom: 5px;
  margin-left: 10px;
  //margin-top: 10px;
}

.teacherBox {
  padding: 20px;
  width: 582px;
  height: 245px;

  .imgBox {
    display: flex;
    justify-content: center;
    align-items: center;

    .medium {
      text-align: center;
    }
  }
}

.imgStyle {
  width: 100px;
  height: 120px;
  margin: 0;
  margin: 0 10px;
}
</style>
