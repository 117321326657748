<template>
  <div id="tab">
    <div class="title">
      <p>{{ titleName }}</p>
    </div>
    <div class="tablList">
      <vueSeamless :data="list"
                   class="seamless-warp">
        <p v-for="(item,index) in list"
           :key="index"
           @click="goInfo">
          {{ item.name }}
        </p>
      </vueSeamless>
    </div>
  </div>
</template>

<script>
import vueSeamless from 'vue-seamless-scroll'

export default {
  props: ['titleName', "list"],
  data: () => ({}),
  components: {
    vueSeamless
  },
  computed: {},
  created() {
  },
  methods: {
    goInfo() {
      this.$router.push('/content/coop?index=4')
      this.$store.commit('changeIndex', 3)
    }
  }
}
</script>

<style lang="scss" scoped>
#tab {
  width: 249px;
  height: 175px;
  margin-top: 10px;
  border: 1px #2073dfb4 solid;
}

.title {
  background-color: #1c5eb5;
  width: 100%;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
  margin-bottom: 10px;
}

.tablList {
  box-sizing: border-box;
  height: 130px;
  overflow: hidden;
  padding: 0 10px;

  p {
    width: 220px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  p:hover {
    color: red;
  }
}
</style>
